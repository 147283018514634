<template>
  <div class="rf-rc-settingss-left rf-rc-col1">
    <div class="promo-design-group flex flex-col gap-4">
      <div class="flex flex-col">
        <v-textarea
          outlined
          :rows="2"
          label="Title"
          v-model.trim="
            currentTranslations.hasOwnProperty('rf_retention_title')
              ? previewActions.rf_retention_title
              : actions.rf_retention_title
          "
          name="title"
          hide-details
          :disabled="currentTranslations.hasOwnProperty('rf_retention_title')"
        ></v-textarea>
        <RfInsertUserTrait
          :app="app"
          actionName="rf_retention_title"
          title="title"
          :actions="actions"
          v-if="!currentTranslations.hasOwnProperty('rf_retention_title')"
        />
      </div>
      <div v-if="!noStyle" class="promo-fonts-size-input">
        <v-text-field
          outlined
          dense
          label="Title Font Size"
          v-model.trim="actions.rf_settings_title_font_size"
          v-validate="'required'"
          name="title-font-size"
          required
          hide-details
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Font Color"
          v-model.trim="actions.rf_settings_fill_color"
          name="fill_color"
          required
          v-validate="'required'"
          hide-details
        ></v-text-field>
      </div>
      <div class="flex flex-col">
        <v-textarea
          outlined
          :rows="rowsNumberMsg"
          label="Message"
          v-model.trim="
            currentTranslations.hasOwnProperty('rf_retention_message')
              ? previewActions.rf_retention_message
              : actions.rf_retention_message
          "
          :disabled="currentTranslations.hasOwnProperty('rf_retention_message')"
          name="message"
          hide-details
        ></v-textarea>
        <RfInsertUserTrait
          :app="app"
          actionName="rf_retention_message"
          title="message"
          :actions="actions"
          v-if="!currentTranslations.hasOwnProperty('rf_retention_message')"
        />
      </div>
      <template v-if="!noStyle">
        <div class="promo-fonts-size-input">
          <v-text-field
            outlined
            dense
            label="Font Size"
            v-model.trim="actions.rf_settings_message_font_size"
            v-validate="'required'"
            name="message-font-size"
            required
            hide-details
          ></v-text-field>
          <v-select
            outlined
            hide-details
            dense
            label="Align"
            v-model="actions.rf_retention_align"
            :items="messageAlignOptions"
          />
          <v-text-field
            outlined
            hide-details
            dense
            label="Top Padding"
            v-model.trim="actions.rf_settings_title_padding_top"
            name="top_padding"
            v-if="model.path_type !== 'email'"
          ></v-text-field>
        </div>
        <div v-if="isDeviceInterstitial" class="promo-fonts-size-input">
          <v-text-field
            outlined
            dense
            hide-details
            type="number"
            label="Side padding"
            :value="paddingValue[0]"
            v-validate="'required'"
            placeholder="e.g.: 10"
            @input="v => (paddingValue = [v, 0])"
          >
            <template #append>
              <v-select
                dense
                :value="paddingValue[1]"
                class="m-0 -mt-2 w-14 p-0"
                hide-details
                :items="DEVICES_MEASURES"
                @input="v => (paddingValue = [v, 1])"
              >
              </v-select>
            </template>
          </v-text-field>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import DesignMixin from "@/utils/DesignMixin";
import RfInsertUserTrait from "@/components/RfPathCreate/RfRetentionActions/RfInsertUserTrait.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";
import { computed } from "vue";
import { DEVICES_MEASURES, useCSSProperty } from "@/utils/composables/useCSSProperty";

export default {
  name: "RfRetentionActionTitleMessage",
  mixins: [DesignMixin, LanguagePreviewMixin],
  components: { RfInsertUserTrait },
  props: {
    actions: Object,
    model: Object,
    app: Object,
    noStyle: { type: Boolean, default: false },
  },
  setup: props => {
    const { variable: paddingValue } = useCSSProperty(
      () => props.actions,
      "rf_settings_body_padding_side",
    );

    const isDeviceInterstitial = computed(() => {
      return (
        props.model.path_type === PromptTypes.interstitial.value &&
        [DefaultDeviceOptions.android_os.value, DefaultDeviceOptions.ios.value].includes(
          props.model.device_type,
        )
      );
    });
    return { isDeviceInterstitial, DEVICES_MEASURES, paddingValue };
  },
};
</script>
