<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="750">
      <v-card tag="form" v-on:submit.prevent="processSubmit">
        <v-card-title class="headline"
          >{{ isUpdating ? "Update" : "Add new" }} Tracker</v-card-title
        >
        <v-card-text>
          <v-form ref="trackerPageForm">
            <div class="input-wrapper">
              <RfTextInput
                outlined
                dense
                class="flex-grow-1"
                placeholder="Your trait's name"
                title="Name"
                :disabled="!!disabled"
                v-model="custom_field.name"
              />
              <RfTextInput
                outlined
                dense
                class="flex-grow-1"
                title="Label"
                v-model="custom_field.label"
                :disabled="isUpdating || !!disabled"
                placeholder="Trait's label"
              />
            </div>
            <RfTextInput
              outlined
              dense
              placeholder="Trait's description"
              title="Description"
              :disabled="!!disabled"
              v-model="custom_field.description"
              textarea
              no-resize
              rows="3"
            />
            <div class="no-value setting-add-tracker-type">
              <h3>Tracker type</h3>
              <v-radio-group
                :disabled="!!disabled"
                row
                style="margin-top: 0"
                v-model="custom_field.data_type"
              >
                <v-radio
                  v-for="n in Object.keys(dataTypes)"
                  :key="n"
                  :label="n"
                  :value="n"
                ></v-radio>
              </v-radio-group>

              <RfTextInput
                outlined
                dense
                :disabled="!!disabled"
                placeholder="Value"
                v-model="custom_field.values[0]"
                v-if="isRedfastConnector"
                v-show="custom_field.data_type === 'track'"
                hide-details
                background-color="#ffffff"
              />
              <div v-if="isPageTracker">
                <RfTextInput
                  outlined
                  dense
                  validate-on-blur
                  :disabled="!!disabled"
                  title="Url Path"
                  v-model="pageSettings.url_path"
                  :rules="[isValidUrlRule]"
                  @blur="validateTrackerForm"
                  background-color="#ffffff"
                  placeholder="/settings"
                />
                <RfTextInput
                  outlined
                  dense
                  validate-on-blur
                  :disabled="!!disabled"
                  title="Query Parameters"
                  v-model.trim="pageSettings.query_params"
                  :rules="[isValidUrlRule]"
                  @blur="validateTrackerForm"
                  background-color="#ffffff"
                  placeholder="version=2020"
                />
                <RfTextInput
                  outlined
                  dense
                  validate-on-blur
                  :disabled="!!disabled"
                  title="Url Hash"
                  v-model.trim="pageSettings.url_hash"
                  :rules="[isValidUrlRule]"
                  @blur="validateTrackerForm"
                  background-color="#ffffff"
                  placeholder="#profile"
                />
              </div>
              <div class="use-reg-expression-opt" v-if="regexAllowed">
                <div class="d-flex align-center">
                  <div class="use-reg-title">Match by:</div>
                  <v-radio-group
                    hide-details
                    class="m-1 ml-4 mt-1 pt-0"
                    :disabled="!!disabled"
                    v-model.trim="custom_field.options.use_regex"
                    @change="validateTrackerForm"
                    row
                  >
                    <v-radio :value="false" label="Wildcards (*)" />
                    <v-radio :value="true" label="Regular Expression" />
                  </v-radio-group>
                </div>
                <button
                  type="button"
                  class="use-reg-test-btn"
                  :disabled="disabled || !containsRegexOrWildcards || !isTrackerFormValid"
                  @click.stop="showRegexTestModal = true"
                >
                  {{ testerLabel }}
                </button>
                <v-dialog
                  v-if="!disabled && containsRegexOrWildcards && isTrackerFormValid"
                  v-model="showRegexTestModal"
                  max-width="550"
                  width="550"
                >
                  <RfRegexTester
                    :testType="testType"
                    :urlPath="pageSettings.url_path"
                    :urlHash="pageSettings.url_hash"
                    :queryParams="pageSettings.query_params"
                    v-on:close="showRegexTestModal = false"
                    :useRegex="custom_field.options.use_regex"
                  />
                </v-dialog>
              </div>
              <div v-if="custom_field.data_type === 'advanced'" class="mt-6">
                <RfSelect
                  outlined
                  dense
                  :disabled="!!disabled"
                  title="Advanced"
                  v-model="custom_field.options.custom_trigger_id"
                  :items="customTriggers"
                />
                <RfTextInput
                  outlined
                  :disabled="!!disabled"
                  dense
                  hide-details
                  type="number"
                  title="Advanced Interval (seconds)"
                  v-model="custom_field.options.custom_trigger_seconds"
                />
                <RfAdvancedArguments ref="advancedArgsBody" />
              </div>
            </div>
            <v-checkbox
              v-if="canNormalize"
              :disabled="!!disabled"
              v-model="custom_field.is_normalized"
              label="Normalize data into 0-10 scale"
              style="margin-top: 0"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn depressed class="cancel-btn px-5" outlined @click="$emit('resetForm')">
            {{ !disabled ? "Cancel" : "Close" }}
          </v-btn>
          <RfButton
            :icon="isUpdating ? 'update' : 'add'"
            :button-text="isUpdating ? 'Update Usage Tracking' : 'Add Usage Tracking'"
            :color="isUpdating ? 'success' : 'accent'"
            type="submit"
            class="px-5"
            :disabled="disabled"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RfRegexTester from "@/components/RfCommonCards/RfRegexTester.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import { mapActions, mapState } from "vuex";
import RfAdvancedArguments from "@/blocks/RfAdvancedArguments.vue";
import { nextTick } from "vue";

export default {
  name: "RfSetupUsageTrackingModal",
  props: ["showDialog", "CtaTitle", "custom_field", "isUpdating", "disabled"],
  components: { RfRegexTester, RfTextInput, RfButton, RfSelect, RfAdvancedArguments },
  data() {
    return {
      showRegexTestModal: false,
      pageSettings: cloneDeep(this.custom_field.values[0]) || {}, // should only be used for page trackers
      isTrackerFormValid: false,
    };
  },
  computed: {
    ...mapState({
      currApp: state => state.apps.currApp,
      clientActions: state => state.apps.clientActions,
    }),
    customTriggers() {
      return this.clientActions
        .filter(action => action.action_type === "custom_trigger")
        .map(action => {
          return { text: action.name, value: action.id };
        });
    },
    isRedfastConnector() {
      return !this.isUpdating || this.custom_field.connector_type === "redfast";
    },
    dataTypes() {
      if (this.isRedfastConnector) {
        return {
          page: "Page",
          track: "Track",
          custom: "Custom",
          advanced: "Advanced",
        };
      }
      return {
        boolean: "Boolean",
        string: "String",
        number: "Number",
        date: "Date",
      };
    },
    canNormalize() {
      if (this.custom_field.display_type === "slider") {
        return true;
      }
      return false;
    },

    testerLabel() {
      return `Test your ${this.testType} expression`;
    },

    testType() {
      return this.custom_field.options.use_regex ? "Regular" : "Wildcard";
    },
    isPageTracker() {
      return this.custom_field.data_type === "page" || this.custom_field.data_type === "advanced";
    },
    regexAllowed() {
      return this.isPageTracker;
    },
    containsRegexOrWildcards() {
      const { url_path, query_params, url_hash } = this.pageSettings;
      const urlStr = [url_path, query_params, url_hash].join();

      return (this.custom_field.options.use_regex ? /[.*+?^${}()|[\]\\]/ : /\*/).test(urlStr);
    },
  },
  methods: {
    ...mapActions(["getClientActions"]),

    hasWildCard(string) {
      return string && string.indexOf("*") >= 0;
    },
    processSubmit() {
      if (!this.validateTrackerForm()) return;
      if (this.isPageTracker) {
        this.custom_field.values[0] = this.pageSettings;
      }
      this.$emit("onSubmit", this.$refs.advancedArgsBody?.get());
    },
    validateTrackerForm() {
      this.isTrackerFormValid = this.$refs.trackerPageForm?.validate();
      return this.isTrackerFormValid;
    },
    isValidUrl(str) {
      if (!str) return true;

      if (this.custom_field.options.use_regex && this.regexAllowed) {
        try {
          return !!new RegExp(str, "i");
        } catch (e) {
          return false;
        }
      }

      // Disallowed characters in Phaser
      return !/[\[\]{}\s"%~#<>]/.test(str);
    },
    isValidUrlRule(str) {
      if (this.isValidUrl(str)) return true;

      return `Invalid ${this.custom_field.options.use_regex ? "Regular expression" : "characters"}`;
    },
  },
  mounted() {
    this.$refs.advancedArgsBody?.init(this.custom_field.options.custom_trigger_args);
    this.getClientActions({ appId: this.currApp.id });
  },
  watch: {
    "custom_field.data_type": function (to, from) {
      if (from === "page" || from === "advanced") {
        this.custom_field.values = [];
      }
      if (to === "page" || to === "advanced") {
        this.pageSettings = {};
      }
      nextTick(this.validateTrackerForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  gap: 0px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(200px, 45%), 1fr));
  grid-template-rows: min-content;
}
</style>
