<template>
  <div class="promo-design-group background-opt-input">
    <v-checkbox
      v-model="backgroundImageEnabled"
      label="Use background image"
      class="background-img-checkbox"
      hide-details
    />
    <div style="padding-top: 10px" class="bgimg-setup-div">
      <div class="upload-bgimg-wrapper">
        <v-btn
          depressed
          color="accent"
          class="upload-image-btn"
          @click="triggerUploadBgImageFromInput('bgImageInput')"
          :disabled="uploadDisabled"
        >
          <v-icon size="14" left>fas fa-upload</v-icon>
          Upload
          <template v-if="!isNewDevicePrompt">desktop</template>
          background
        </v-btn>
        <input
          type="file"
          style="display: none"
          ref="bgImageInput"
          accept="image/*"
          @change="uploadBgImageFromInput('desktop')"
        />
        <div class="upload-img-helper">
          {{ imgDimensionsText }}
        </div>
      </div>
      <div class="pr-bg-image-options-row" v-if="isNotEmailPromo">
        <v-select
          outlined
          hide-details
          dense
          label="Position"
          v-model="actions.rf_retention_img_align"
          :items="bgImageAlignOptions"
          v-if="!isDeviceInterstitial"
        />
        <v-select
          outlined
          hide-details
          dense
          label="Adjust size"
          :disabled="!backgroundImageEnabled"
          v-model="actions.rf_settings_tile_is_responsive"
          :items="bgImageSize"
          v-if="isHorizontalVerticalTile"
        />
        <v-text-field
          outlined
          dense
          hide-details
          label="Bg Color"
          v-model.trim="actions.rf_settings_bg_image_color"
          :error-messages="errors.first('bg-image-color')"
          name="bg-image-color"
          placeholder="e.g.: #336699"
          style="padding: 5px 0 2px"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>
<script>
import UploadImageMixin from "@/utils/UploadImageMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import { isEmail } from "@/utils/prompts/promptHelpers";
import { mapState } from "vuex";
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";

export default {
  name: "RfRetentionImageUpload",
  props: ["actions", "model"],
  mixins: [UploadImageMixin, LanguagePreviewMixin],
  data() {
    return {
      rf_settings_bg_image: this.model.id ? this.model.actions.rf_settings_bg_image : null,
      rf_settings_mobile_bg_image: this.model.id
        ? this.model.actions.rf_settings_mobile_bg_image
        : null,
      backgroundImageEnabled: this.model.id ? !!this.model.actions.rf_settings_bg_image : true,
      bgImageSize: [
        { value: "cover", text: "Fill" },
        { value: "contain", text: "Fit" },
        { value: "auto", text: "Actual" },
      ],
      bgImageAlignOptions: [
        { value: "left", text: "Left" },
        { value: "right", text: "Right" },
      ],
    };
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    isNewAndroid() {
      return (
        this.currApp?.flags?.new_android_editor &&
        this.model.device_type === DefaultDeviceOptions.android_os.value
      );
    },
    isAndroidDevice() {
      return this.model.device_type === DefaultDeviceOptions.android_os.value;
    },
    isNewAppleEditorFlag() {
      return !this.currApp?.flags?.legacy_apple_editor;
    },
    isIosDevice() {
      return DefaultDeviceOptions.ios.value === this.model.device_type;
    },
    isTvosDevice() {
      return DefaultDeviceOptions.tv_os.value === this.model.device_type;
    },
    isNewAppleEditor() {
      return this.isNewAppleEditorFlag && (this.isIosDevice || this.isTvosDevice);
    },
    isNewDevicePrompt() {
      return (
        this.model.path_type === "retention_modal" && (this.isNewAndroid || this.isNewAppleEditor)
      );
    },
    isNotEmailPromo() {
      return !isEmail(this.model);
    },
    backgroundImageOptionEnabled() {
      if (this.model.path_type === "interstitial") {
        return false;
      }
      return this.model.actions.rf_settings_bg_image;
    },
    isHorizontalVerticalTile() {
      const pathType = this.model.path_type;
      return (
        pathType === "horizontal" ||
        pathType === "vertical" ||
        pathType === "tile" ||
        pathType === "interstitial"
      );
    },
    uploadDisabled() {
      if (!this.backgroundImageEnabled) return true;

      return this.currentTranslations.hasOwnProperty("rf_settings_bg_image");
    },
    isDeviceInterstitial() {
      return this.model.path_type === "interstitial" && (this.isIosDevice || this.isAndroidDevice);
    },
    imgDimensionsText() {
      if (this.isDeviceInterstitial) {
        return "2400x1200 px recommended";
      } else if (this.isNewAndroid || (this.isNewAppleEditorFlag && this.isTvosDevice)) {
        return "1024 x 768 px recommended";
      } else if (this.isNewAppleEditorFlag && this.isIosDevice) {
        return "1024 x 1024 px recommended";
      }
    },
  },
  methods: {
    triggerUploadBgImageFromInput(displayType) {
      if (displayType == "bgImageInput") {
        this.$refs.bgImageInput.click();
      } else {
        this.$refs.bgImageMobileWebInput.click();
      }
    },
  },
  watch: {
    backgroundImageEnabled(to) {
      this.actions.rf_settings_bg_image = to ? this.rf_settings_bg_image : null;
      this.actions.rf_settings_mobile_bg_image = to ? this.rf_settings_mobile_bg_image : null;
      this.$emit("forceParentRender");
    },
  },
};
</script>
