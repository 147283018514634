<template>
  <div class="flex-column flex gap-5">
    <RfSimpleCard class="px-10 py-8">
      When users visit or log into your site, a unique identifier (User ID) is typically stored in
      their browsers. Redfast needs to access this to differentiate between visitors for customizing
      actions, and for downstream reporting. You may need your developers' help with completing this
      form, before Redfast is fully functional. For technical details or advanced usage, you can
      view our
      <a href="https://help.redfast.com/docs/custom-js-snippet">documentation</a>.
    </RfSimpleCard>
    <RfSimpleCard class="px-10 py-8">
      <div class="user-matching-settings">
        <div class="rf-settings-section-title">
          <h4>Authenticated User ID Matching</h4>
        </div>
        <div>
          Redfast can directly access your authenticated visitors&apos; User ID if the following
          store location and key is specified. If the resulting value is encoded, you can specify
          how it should be decoded. Finally, if the resulting value is a JSON string, it will
          automatically be parsed, and you can specify a path to access the desired property.
        </div>
        <div class="align-center flex gap-4">
          <label class="text-sm font-bold">Fetch from:</label>
          <v-radio-group
            row
            v-model="settingsForm.userid_store_type"
            :disabled="isDisabledRoleMixin"
          >
            <v-radio value="localstorage" label="localStorage" />
            <v-radio value="sessionstorage" label="sessionStorage" />
            <v-radio value="cookie" label="cookie" />
            <v-radio value="window" label="window" />
            <v-radio
              value=""
              label="Custom JS Snippet"
              class="ml-1 border-l border-solid border-strokes pl-3"
            />
          </v-radio-group>
        </div>
        <RfTextInput
          outlined
          title="Store key:"
          placeholder="e.g. ajs_user_id"
          dense
          v-model.trim="settingsForm.userid_store_key"
          :disabled="useJSSnippet || isDisabledRoleMixin"
        />
        <div class="align-center -mt-5 mb-1 flex gap-4">
          <label class="text-sm font-bold">Decode using:</label>
          <v-radio-group
            row
            v-model="settingsForm.userid_decode"
            :disabled="useJSSnippet || isDisabledRoleMixin"
          >
            <v-radio value="" label="none" />
            <v-radio value="base64" label="Base64" />
            <v-radio value="jwt" label="JWT" />
          </v-radio-group>
        </div>
        <RfTextInput
          outlined
          title="Property path:"
          placeholder="e.g. auth.user.id"
          dense
          v-model.trim="settingsForm.userid_prop_path"
          :disabled="useJSSnippet || isDisabledRoleMixin"
        />
      </div>

      <div class="rf-settings-section-title mt-8">
        <h4>Test Account Credentials</h4>
      </div>
      <div class="mb-6">
        <p>
          Providing login details for a test account on your site will help expedite the setup
          process. With this, we can help verify if the User ID is matched properly, troubleshoot,
          or suggest possible values for the above form.
        </p>
        <p>
          <strong>Note:</strong> these credentials are visible to Redfast employees and all users in
          your organization. Do ensure that the test account contains no sensitive information and
          cannot perform any potentially damaging actions.
        </p>
      </div>
      <RfTextInput
        outlined
        title="Login Page URL:"
        placeholder="e.g. http://your-site.com/login"
        dense
        v-model.trim="providerForm.url"
        :disabled="isDisabledRoleMixin"
      />
      <RfTextInput
        outlined
        title="Username / Email:"
        placeholder="e.g. tester@your-site.com"
        dense
        v-model.trim="providerForm.login"
        :disabled="isDisabledRoleMixin"
      />
      <RfTextInput
        outlined
        title="Password:"
        placeholder="e.g. abc123"
        dense
        v-model.trim="providerForm.plaintext_password"
        :disabled="isDisabledRoleMixin"
      />
    </RfSimpleCard>
  </div>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RoleMixin from "@/utils/RoleMixin";
import { computed, reactive } from "vue";

export default {
  name: "RfSetupUserId",
  mixins: [RoleMixin],
  props: ["app"],
  components: { RfSimpleCard, RfTextInput },
  setup(props) {
    const settingsForm = reactive({
      userid_store_type: "",
      userid_decode: "",
      ...props.app.web_snippet_settings,
    });
    const providerForm = reactive({ ...props.app.providers.test_account });

    const useJSSnippet = computed(() => !settingsForm.userid_store_type);

    const fillModel = model => {
      model.web_snippet_settings = { ...model.web_snippet_settings, ...settingsForm };
      model.providers.test_account = { ...model.providers.test_account, ...providerForm };
      const { web_snippet_settings } = model;
      if (!web_snippet_settings.userid_store_type) delete web_snippet_settings.userid_store_type;
      if (!web_snippet_settings.userid_decode) delete web_snippet_settings.userid_decode;
      return model;
    };

    return { settingsForm, providerForm, useJSSnippet, fillModel };
  },
};
</script>

<style lang="scss" scoped>
.user-matching-settings :deep(.rf-text-input--title) {
  @apply text-sm font-bold text-black-2;
}
</style>
