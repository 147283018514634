<template>
  <div class="promo-design-group">
    <template v-if="!(isBanner && ['ios', 'tv_os', 'android_os'].includes(model.device_type))">
      <div>
        <div class="promo-all-buttons-inputs">
          <div class="promo-fonts-size-input">
            <v-text-field
              outlined
              dense
              label="Button 1 Text"
              v-model.trim="
                currentTranslations.hasOwnProperty('rf_retention_button1_text')
                  ? previewActions.rf_retention_button1_text
                  : actions.rf_retention_button1_text
              "
              :disabled="currentTranslations.hasOwnProperty('rf_retention_button1_text')"
              :error-messages="errors.first('confirm-button')"
              name="confirm-button"
              placeholder="e.g.: Sign me up"
              hide-details
              background-color="#ffffff"
            ></v-text-field>
            <v-text-field
              hide-details
              outlined
              dense
              label="Button 1 Color"
              v-model.trim="actions.button1_bg_color"
              :error-messages="errors.first('background_color')"
              name="background_color"
              placeholder="e.g.: #336699"
              background-color="#ffffff"
            ></v-text-field>
          </div>
          <div class="promo-fonts-size-input promo-secondary-inputs">
            <v-text-field
              outlined
              dense
              label="Button 1 Text Color"
              v-model.trim="actions.button1_text_color"
              :error-messages="errors.first('confirm-button-text-color')"
              name="confirm-button-text-color"
              placeholder="e.g.: #FFFFFF"
              hide-details
              background-color="#ffffff"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Radius"
              v-model.trim="actions.rf_email_button_radius"
              placeholder="e.g.: 10px"
              hide-details
              background-color="#ffffff"
              v-if="isEmail(model)"
            ></v-text-field>
            <v-text-field
              hide-details
              outlined
              dense
              label="Button Text Highlight"
              v-model.trim="actions.button1_highlight_color"
              :error-messages="errors.first('confirm-button-text-highlight')"
              name="confirm-button-text-highlight"
              placeholder="e.g.: #FFFF00"
              background-color="#ffffff"
              v-if="isModal && model.device_type !== 'web'"
            ></v-text-field>
          </div>
        </div>
        <div>
          <RfFadeTimer
            :actions="actions"
            title="Fadein Timer"
            actionName="rf_settings_delay_fadein_seconds"
            v-if="webPopup"
          />
          <RfPromoInteractionInterval
            interactionType="clickButton12"
            :action="actions.rf_settings_accept_interval"
            v-on:setIntervalAction="setIntervalAction"
          />
          <RfFadeTimer
            :actions="actions"
            title="Button 1 Fadeout Timer"
            actionName="rf_settings_delay_fadeout_seconds"
            v-if="isModal && !isDeviceInterstitial"
          />
        </div>
        <div class="rf-optional-button-configs" v-if="isWebOrCustomInline || isModal">
          <v-checkbox
            v-model="actions.rf_settings_confirm_button_2_enabled"
            value="true"
            label="Button 2"
            hide-details
          />
          <div
            class="secondary-btn-opts allow-decline-ct"
            v-if="actions.rf_settings_confirm_button_2_enabled === 'true'"
          >
            <div class="promo-fonts-size-input">
              <v-text-field
                outlined
                dense
                label="Button 2 Text"
                v-model.trim="
                  currentTranslations.hasOwnProperty('rf_retention_button2_text')
                    ? previewActions.rf_retention_button2_text
                    : actions.rf_retention_button2_text
                "
                :disabled="currentTranslations.hasOwnProperty('rf_retention_button2_text')"
                :error-messages="errors.first('confirm-button2')"
                name="confirm-button2"
                placeholder="e.g.: Add to list"
                hide-details
                background-color="#ffffff"
              />
              <v-text-field
                hide-details
                outlined
                dense
                label="Button 2 Color"
                v-model.trim="actions.button2_bg_color"
                placeholder="e.g.: #336699"
                background-color="#ffffff"
              ></v-text-field>
            </div>
            <div class="promo-fonts-size-input promo-secondary-inputs">
              <v-text-field
                outlined
                dense
                label="Button 2 Text Color"
                v-model.trim="actions.button2_text_color"
                :error-messages="errors.first('confirm-button-2-text-color')"
                name="confirm-button-2-text-color"
                placeholder="e.g.: #FFFFFF"
                hide-details
                background-color="#ffffff"
              ></v-text-field>
              <v-text-field
                hide-details
                outlined
                dense
                label="Button 2 Text Highlight"
                v-model.trim="actions.button2_highlight_color"
                :error-messages="errors.first('confirm-button-2-text-highlight')"
                name="confirm-button-2-text-highlight"
                placeholder="e.g.: #FFFF00"
                background-color="#ffffff"
                v-if="isModal && model.device_type !== 'web'"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="rf-optional-button-configs" v-if="isWebOrCustomInline || isModal">
          <v-checkbox
            v-model="actions.rf_settings_cancel_button_enabled"
            value="true"
            label="Button 3"
            hide-details
          />
          <div class="allow-decline-ct" v-if="actions.rf_settings_cancel_button_enabled === 'true'">
            <div class="promo-fonts-size-input">
              <v-text-field
                outlined
                dense
                label="Button 3 Text"
                v-model.trim="
                  currentTranslations.hasOwnProperty('rf_retention_button3_text')
                    ? previewActions.rf_retention_button3_text
                    : actions.rf_retention_button3_text
                "
                :disabled="currentTranslations.hasOwnProperty('rf_retention_button3_text')"
                :error-messages="errors.first('cancel-button')"
                name="cancel-button"
                hide-details
                placeholder="e.g.: No, thanks"
                background-color="#ffffff"
              ></v-text-field>
              <v-text-field
                hide-details
                outlined
                dense
                label="Button 3 Color"
                v-model.trim="actions.button3_bg_color"
                :error-messages="errors.first('b3_background_color')"
                name="b3_background_color"
                placeholder="e.g.: #336699"
                background-color="#ffffff"
                v-if="isDeviceInterstitial"
              ></v-text-field>
            </div>
            <div class="promo-fonts-size-input promo-secondary-inputs">
              <v-text-field
                outlined
                dense
                label="Button 3 Text Color"
                v-model.trim="actions.button3_text_color"
                :error-messages="errors.first('cancel-button-text-color')"
                name="cancel-button-text-color"
                placeholder="e.g.: #FFFFFF"
                hide-details
                background-color="#ffffff"
              ></v-text-field>
              <v-text-field
                hide-details
                outlined
                dense
                label="Button 3 Text Highlight"
                v-model.trim="actions.button3_highlight_color"
                :error-messages="errors.first('cancel-button-text-highlight')"
                name="cancel-button-text-highlight"
                placeholder="e.g.: #FFFF00"
                background-color="#ffffff"
                v-if="isModal && model.device_type !== 'web'"
              ></v-text-field>
            </div>
            <RfPromoInteractionInterval
              interactionType="clickButton3"
              :action="actions.rf_settings_decline_interval"
              v-on:setIntervalAction="setIntervalAction"
            />
          </div>
        </div>
      </div>
      <div style="padding-top: 15px" v-if="isDeviceInterstitial">
        Button Border
        <div class="mt-2 flex items-center justify-between gap-2">
          <v-text-field
            outlined
            dense
            hide-details
            type="number"
            label="Radius"
            :value="buttonBorderRadius[0]"
            placeholder="e.g.: 400"
            @input="v => (buttonBorderRadius = [v, 0])"
          >
            <template #append>
              <v-select
                dense
                :value="buttonBorderRadius[1]"
                class="m-0 -mt-2 w-14 p-0"
                hide-details
                :items="[NONE_MEASURE, ...DEVICES_MEASURES]"
                @input="v => (buttonBorderRadius = [v, 1])"
              >
              </v-select>
            </template>
          </v-text-field>
          <v-text-field
            outlined
            dense
            hide-details
            type="number"
            label="Width"
            :value="buttonBorderWidth[0]"
            placeholder="e.g.: 400"
            @input="v => (buttonBorderWidth = [v, 0])"
          >
            <template #append>
              <v-select
                dense
                :value="buttonBorderWidth[1]"
                class="m-0 -mt-2 w-14 p-0"
                hide-details
                :items="DEVICES_MEASURES"
                @input="v => (buttonBorderWidth = [v, 1])"
              >
              </v-select>
            </template>
          </v-text-field>
          <RfMenu hideIcon minFit preset="promptStatus">
            <template #activator>
              <v-text-field
                v-model="actions.rf_retention_button_border_color"
                outlined
                dense
                hide-details
                label="Color"
              >
              </v-text-field>
            </template>
            <template #menu>
              <v-color-picker
                canvas-height="110"
                v-model.trim="actions.rf_retention_button_border_color"
                dot-size="30"
                mode="hexa"
                class="window-transparency-picker w-72"
                outlined
              ></v-color-picker>
            </template>
          </RfMenu>
        </div>
      </div>
      <div style="padding-top: 15px" v-if="isWebOrCustomInline || isModal">
        <v-checkbox
          v-model="thankYouEnabled"
          label="Show confirmation message"
          :disabled="currentTranslations.hasOwnProperty('rf_retention_acceptance_text')"
          class="background-img-checkbox"
        />
        <v-text-field
          v-if="thankYouEnabled"
          :class="thankYouEnabled ? '' : 'disabled-button'"
          dense
          outlined
          required
          label="Confirmation message"
          placeholder="e.g.: Thank you!"
          v-model.trim="
            currentTranslations.hasOwnProperty('rf_retention_acceptance_text')
              ? previewActions.rf_retention_acceptance_text
              : actions.rf_retention_acceptance_text
          "
          :disabled="currentTranslations.hasOwnProperty('rf_retention_acceptance_text')"
          background-color="#ffffff"
          hide-details
          style="margin: 0 0 15px 28px"
        />
      </div>
    </template>
    <div v-if="showCloseOptions">
      <v-checkbox
        v-model="actions.rf_settings_close_button_enabled"
        label="Allow close"
        value="true"
        class="promotion-closeBtn-checkbox"
      />
      <v-checkbox
        v-model="actions.rf_settings_click_outside_close_enabled"
        v-if="isBanner"
        label="Click outside to close"
        value="true"
        class="promotion-closeBtn-checkbox"
      />
    </div>
    <div v-if="actions.rf_settings_close_button_enabled === 'true'">
      <div style="margin: -10px 0 10px 28px">
        <RfPromoInteractionInterval
          interactionType="dismiss"
          :action="actions.rf_settings_dismiss_interval"
          v-on:setIntervalAction="setIntervalAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfFadeTimer from "@/components/RfPathCreate/RfRetentionActions/RfFadeTimer.vue";
import { isEmail } from "@/utils/prompts/promptHelpers";
import RfMenu from "@/components/menus/RfMenu.vue";
import { DEVICES_MEASURES, NONE_MEASURE, useCSSProperty } from "@/utils/composables/useCSSProperty";

export default {
  name: "RfRetentionButtons",
  props: ["actions", "model", "showCloseOptions", "isCustomDevicesEnabled"],
  setup: props => {
    const { variable: buttonBorderRadius } = useCSSProperty(
      () => props.actions,
      "rf_retention_button_border_radius",
      { canBeEmpty: true },
    );
    const { variable: buttonBorderWidth } = useCSSProperty(
      () => props.actions,
      "rf_retention_button_border_thickness",
    );
    return { isEmail, buttonBorderRadius, buttonBorderWidth, DEVICES_MEASURES, NONE_MEASURE };
  },
  components: { RfPromoInteractionInterval, RfFadeTimer, RfMenu },
  mixins: [LanguagePreviewMixin],
  data() {
    return {
      thankYouEnabled: false,
    };
  },
  computed: {
    isInline() {
      return ["tile", "horizontal", "vertical"].indexOf(this.model.path_type) > -1;
    },
    isWebOrCustomInline() {
      return this.isInline && (this.model.device_type === "web" || this.isCustomDevicesEnabled);
    },
    isModal() {
      return (
        [
          "retention_modal",
          "invisible",
          "interstitial",
          "video",
          "widget",
          "bottom_banner",
        ].indexOf(this.model.path_type) > -1
      );
    },
    webPopup() {
      return this.model.path_type === "retention_modal" && this.model.device_type === "web";
    },
    isBanner() {
      return this.model.path_type === "bottom_banner";
    },
    isDeviceInterstitial() {
      return (
        this.model.path_type === "interstitial" &&
        ["ios", "android_os"].includes(this.model.device_type)
      );
    },
  },
  watch: {
    thankYouEnabled(to) {
      if (!to) {
        if (!this.isLocalized) {
          this.actions.rf_retention_acceptance_text = null;
          this.$emit("forceRender");
        }
      }
    },
  },
  methods: {
    setIntervalAction(value, type) {
      this.actions[`rf_settings_${type}_interval`] = value;
    },
  },
  mounted() {
    this.thankYouEnabled = this.model.id
      ? !!this.readOnlyActions.rf_retention_acceptance_text
      : true;
  },
};
</script>
<style scoped>
.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}
.rf-optional-button-configs {
  padding: 15px 0 0;
}
.hide-buttons-opt {
  display: none;
}
</style>
