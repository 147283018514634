export default {
  timeout: { text: "timeout", value: "timeout" },
  dismiss: { text: "dismiss", value: "dismiss" },
  accept: { text: "accept", value: "accept" },
  accept2: { text: "accept2", value: "accept2" },
  decline: { text: "decline", value: "decline" },
  clickButton1: { text: "button 1 click", value: "accept" },
  clickButton12: { text: "button 1 click or button 2 click", value: "accept" },
  clickButton3: { text: "button 3 click ", value: "decline" },
  customGoal: { text: "custom goal", value: "custom_goal" },
};

export const getAllActivities = () => ({
  goal: { value: "Click 1", key: "goal" },
  goal2: { value: "Click 2", key: "goal2" },
  decline: { value: "Click 3", key: "decline" },
  impression: { value: "Impression", key: "impression" },
  dismiss: { value: "Dismiss", key: "dismiss" },
  custom_goals_seen: { value: "Custom Goal", key: "custom_goals_seen" },
  custom_goals_holdout: { value: "Custom Goal Holdout", key: "custom_goals_holdout" },
  holdout: { key: "holdout", value: "Holdout" },
  timeout: { key: "timeout", value: "Timeout" },
});

export const interactionDenominators = {
  seen: "impressions",
  accepted: "goals",
  accepted2: "goals2",
  declined: "declines",
  dismissed: "dismissals",
};
