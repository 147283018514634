<template>
  <div class="rf-exp-graph-container">
    <div class="segment-overview-stat-row" style="padding-left: 15px">
      <RfCustomGoalInteractionSelector
        :model="model"
        ref="interaction_selector"
        v-if="hasCustomGoal && currExperiment && !isInvisible"
        :key="currExperiment.id"
      />
      <div class="chart-filter-wrapper">
        <div class="chart-filter-date-select">
          <v-btn
            depressed
            class="mr-2"
            text
            color="primary"
            @click="exportExperimentsToCsv(runningStat)"
          >
            Export to CSV
          </v-btn>
          <div class="chart-filter-label">Date range:</div>
          <RfMetricsDateRangeSelector
            parentPage="promotions"
            v-on:fetchMetrics="fetchMetrics"
            v-on:fetchMetricsCustom="fetchMetricsCustom"
            :sinceStartDate="true"
          />
        </div>
      </div>
    </div>
    <template v-if="chartData">
      <v-simple-table class="experiments-results-table experiment-stats-report-details-wrapper">
        <thead>
          <tr>
            <th class="col-exp-1">Variation</th>
            <th class="col-width-1a">Users</th>
            <template v-if="!isInvisible">
              <th class="col-width-1a" v-if="customGoalInteraction !== 'seen'">Impressions</th>
              <th class="col-width-1a">{{ interactionsTitle }}</th>
              <th class="col-width-1a" v-if="shouldShowCTR">{{ ctrTitle }}</th>
            </template>
            <th v-if="hasCustomGoal">{{ isInvisible ? "Conversions" : "Goal Completion" }}</th>
            <th class="text-left">Conversion Rate</th>
            <th class="text-left">Statistical Significance*</th>
            <template v-if="accept2Enabled && !hasCustomGoal">
              <th class="text-left">Clicks (additional)</th>
              <th class="text-left">CTR (additional)</th>
              <th class="text-left">{{ customGoalName }} Conversion Rate (additional)</th>
              <th class="text-left">Statistical Significance* (additional)</th>
            </template>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in runningStat" :key="index">
            <td>
              <button class="rf-exp-graph-variant" @click="setPieVariant(item)">
                <span :style="item.variantColor">{{ item.variantName }}</span>
              </button>
            </td>
            <template v-if="!isInvisible">
              <td>{{ formatUsers(item) }}</td>
              <td v-if="customGoalInteraction !== 'seen'">{{ formatImpressions(item) }}</td>
            </template>
            <td>{{ formatGoals(item) }}</td>
            <td v-if="!isInvisible && shouldShowCTR">{{ formatCTR(item) }}</td>
            <td v-if="hasCustomGoal">{{ formatCustomGoals(item) }}</td>
            <td>{{ formatConversion(item) }}</td>
            <td>
              <span :style="confidenceIndicator(item).style">{{
                confidenceIndicator(item).confidence
              }}</span>
            </td>
            <template v-if="accept2Enabled && !hasCustomGoal">
              <td>{{ formatGoals2(item) }}</td>
              <td>{{ formatCTR2(item) }}</td>
              <td>{{ formatConversion2(item) }}</td>
              <td>
                <span :style="confidenceIndicator(item, true).style">{{
                  confidenceIndicator(item, true).confidence
                }}</span>
              </td>
            </template>
            <td align="center">
              <RfButton
                v-if="item.is_visible"
                button-text="Use this"
                small
                class="use-this-variant"
                color="accent"
                :disabled="isDisabledRoleMixin"
                @click="confirmModal(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <button class="rf-exp-graph-variant" @click="$emit('update:pieActionGroupId', null)">
                Total
              </button>
            </td>
            <template v-if="!isInvisible">
              <td>{{ totalUsersText }}</td>
              <td v-if="customGoalInteraction !== 'seen'">{{ totalImpressionsText }}</td>
            </template>
            <td>{{ totalGoalsText }}</td>
            <td v-if="!isInvisible && shouldShowCTR">{{ displayCTR }}</td>
            <td v-if="hasCustomGoal">{{ totalCustomGoalsText }}</td>
            <td></td>
            <td></td>
            <template v-if="accept2Enabled && !hasCustomGoal">
              <td>{{ totalGoals2Text }}</td>
              <td>{{ totalCTR2 }}</td>
              <td></td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-body px-5">
        *Statistical significance calculations are for informational and illustrative purposes only.
        Raw data is available to you for more advanced analysis.
      </div>
    </template>
    <div class="flex justify-end" style="padding-right: 15px">
      <RfSelect
        v-if="pieMetrics?.length > 1 || isSurvey"
        :value="pieActionGroupId"
        hide-details
        outlined
        dense
        :items="[
          ...runningStat.filter(el => !el?.is_control),
          { variantName: 'Total', variant: { id: null } },
        ]"
        item-text="variantName"
        item-value="variant.id"
        :menu-props="{ offsetY: true }"
        @input="v => $emit('update:pieActionGroupId', v)"
      />
    </div>
    <div class="chartdata-pie-wrapper" :class="chartPieWrapperClassName">
      <div
        v-if="((isInvisible && hasCustomGoal) || !isInvisible) && chartData"
        class="chart--left-col"
      >
        <GChart :data="chartData" :options="chartOptions" :create-chart="createColumnChart" />
      </div>
      <div v-if="pieMetrics?.length > 1" class="chart--right-col">
        <div class="piechart-exp--name">
          <h4>{{ pieActionGroupName }}</h4>
        </div>
        <div>
          <GChart :options="pieChartOptions" :data="pieMetrics" :create-chart="createPieChart" />
        </div>
      </div>
    </div>
    <div v-if="pieActionGroupId && isSurvey" class="mt-4 flex gap-8" style="padding: 0 15px">
      <v-card style="flex: 2">
        <div class="segment-overview-header retention-stat-h bg-white-1">
          <h4>Survey Interactions</h4>
        </div>
        <RfSurveyChart :hasData="customMetricsTotal" :surveyChartData="surveyChartData" />
      </v-card>
      <v-card style="flex: 1">
        <div class="segment-overview-header retention-stat-h bg-white-1">
          <h4>Survey Metrics</h4>
        </div>
        <RfSurveyMetricsTable :surveyChartData="surveyChartData" :totalData="customMetricsTotal" />
      </v-card>
    </div>
    <v-dialog v-model="confirmDialog" width="800">
      <v-card>
        <v-card-title class="headline">
          Use {{ variantItem.variantName }} confirmation
        </v-card-title>
        <v-card-text>
          You've selected {{ variantItem.variantName }} as the winner of this experiment.<br />Are
          you sure you want to use this variation? This ends the experiment.
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined @click="closeConfirmDialog">
            Cancel
          </v-btn>
          <v-btn
            depressed
            color="success"
            @click="onWinner(variantItem.variant.id, false)"
            large
            class="expriment-winner-button"
            style="width: 200px"
          >
            <span class="exp-winner-btn-inner">Use {{ variantItem.variantName }}</span>
          </v-btn>
          <v-btn
            depressed
            color="accent"
            @click="onWinner(variantItem.variant.id, true)"
            large
            class="expriment-winner-button"
            style="width: 250px"
          >
            <span class="exp-winner-btn-inner"
              >Use {{ variantItem.variantName }} & pause prompt</span
            >
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MetricsUtils from "@/utils/MetricsUtils";
import MetricsMixin from "@/utils/MetricsMixin";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import RfCustomGoalInteractionSelector from "@/components/RfCommonCards/RfCustomGoalInteractionSelector.vue";
import CustomGoalInteractionMixin from "@/utils/CustomGoalInteractionMixin";
import { PIE_CHART_COLOR_LIST } from "@/utils/constants/ChartColorsConstants.js";
import { TITLE_ROW } from "@/utils/constants/DisplayMetricsConstants.js";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import { createPieChart, createColumnChart } from "@/utils/vueGoogleChartUtils";
import { percentage } from "@/utils/metricsHelpers";
import { isAccept2Enabled, isInvisible } from "@/utils/prompts/promptHelpers";
import ExperimentCsvExportMixin from "@/utils/ExperimentCsvExportMixin";
import { computed } from "vue";
import StringUtils from "@/utils/StringUtils";
import RfSelect from "../inputs/RfSelect.vue";
import RfSurveyChart from "@/blocks/RfCharts/RfSurveyChart.vue";
import RfSurveyMetricsTable from "@/blocks/RfTable/RfSurveyMetricsTable.vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { interactionDenominators } from "@/utils/constants/PromoInteractionConstants";

export default {
  name: "RfExperimentStatistics",
  props: [
    "metrics",
    "model",
    "pieActionGroupId",
    "customMetricsTotal",
    "surveyChartData",
    "isSurvey",
  ],
  components: {
    RfMetricsDateRangeSelector,
    RfCustomGoalInteractionSelector,
    RfButton,
    RfSelect,
    RfSurveyChart,
    RfSurveyMetricsTable,
  },
  mixins: [MetricsMixin, CustomGoalInteractionMixin, RoleMixin, ExperimentCsvExportMixin],
  setup: props => ({
    createPieChart,
    createColumnChart,
    isInvisible: computed(() => isInvisible(props.model)),
    formatStatsFixed: StringUtils.formatStatFixed,
  }),
  data() {
    return {
      metricsKey: null,
      confirmDialog: false,
      variantItem: {},
      singleDayCustom: false,
      pieChartOptions: {
        height: 400,
        pieSliceText: "label",
        colors: PIE_CHART_COLOR_LIST,
        enableInteractivity: true,
        pieHole: 0.5,
        fontSize: 13,
        bold: true,
        legend: {
          alignment: "none",
          position: "none",
          textStyle: {},
        },
        chartArea: {
          left: 0,
          right: 0,
          top: "10%",
          bottom: "10%",
        },
      },
    };
  },
  computed: {
    ...mapState({ currExperiment: state => state.apps.currExperiment }),
    actions() {
      if (this.model) {
        return this.model.actions;
      }
      return {};
    },
    accept2Enabled() {
      return !!this.variations.find(isAccept2Enabled);
    },
    variations() {
      if (this.currExperiment && this.currExperiment.variations) {
        return this.currExperiment.variations.filter(item => item.is_visible || item.is_control);
      }
      return [];
    },
    filteredStats() {
      if (this.metrics[this.metricsKey] && this.metrics[this.metricsKey].data) {
        const result = [];
        this.variations.forEach(variation => {
          if (variation.traffic_percent > 0) {
            const data = this.metrics[this.metricsKey].data.find(item => item.id === variation.id);
            if (data) result.push(data);
          }
        });
        return result;
      }
      return [];
    },
    runningStat() {
      const colors = [
        "color:#4D96E6",
        "color:#9DC9F7",
        "color:#234569",
        "color:#245897",
        "color:#CBE3FC",
        "color:#63CCFF",
      ];
      const stats = this.filteredStats.map(item => ({
        variant: item,
        variantName: `${item.name}`,
        is_visible: item.is_visible,
        is_control: item.is_control,
        is_baseline: item.is_baseline,
        variantColor: colors.shift(),
        ctr: percentage(item.goals.uniques, item.impressions.count),

        conversion_rate: this.conversionRate(item),
        goals: item.goals.uniques,
        custom_goals: (item.custom_goals && item.custom_goals.uniques) || 0,
        impressions: item.impressions.count,
        uimpressions: item.uimpressions.uniques,
        uholdouts: item.uholdouts.uniques,
        goals2: item.goals2.uniques,
        custom_goals_b2: (item.custom_goals_b2 && item.custom_goals_b2.uniques) || 0,
        ctr2: percentage(item.goals2.uniques, item.impressions.count),
        conversion_rate2: this.conversionRate2(item),
        custom_goals_accepted:
          (item.custom_goals_accepted && item.custom_goals_accepted.uniques) || 0,
        custom_goals_accepted2:
          (item.custom_goals_accepted2 && item.custom_goals_accepted2.uniques) || 0,
        custom_goals_declined:
          (item.custom_goals_declined && item.custom_goals_declined.uniques) || 0,
        custom_goals_dismissed:
          (item.custom_goals_dismissed && item.custom_goals_dismissed.uniques) || 0,
        custom_goals_seen: (item.custom_goals_seen && item.custom_goals_seen.uniques) || 0,
        declines: (item.declines && item.declines.uniques) || 0,
        dismissals: (item.dismissals && item.dismissals.uniques) || 0,
        custom_goals_holdout: (item.custom_goals_holdout && item.custom_goals_holdout.uniques) || 0,
      }));
      return stats;
    },
    totalImpressionsText() {
      return this.formatStatsLocale(this.totalImpressions);
    },
    totalUsersText() {
      return this.formatStatsLocale(this.totalUsers);
    },
    totalGoalsText() {
      return this.formatStatsLocale(
        this.totalGoals + ((this.isInvisible && this.holdoutStatsItem?.uholdouts) || 0),
      );
    },
    totalCustomGoalsText() {
      return this.formatStatsLocale(this.totalCustomGoals);
    },
    totalGoals2Text() {
      return this.formatStatsLocale(this.totalGoals2);
    },
    totalImpressions() {
      return this.sumStats("impressions");
    },
    totalUsers() {
      const holdouts = this.holdoutStatsItem.uholdouts || 0;
      return this.sumStats("uimpressions") + holdouts;
    },
    totalGoals() {
      if (!this.hasCustomGoal) return this.sumStats("goals");
      const statType = interactionDenominators[this.customGoalInteraction];
      return this.sumStats(statType);
    },
    totalCustomGoals() {
      const statType = `custom_goals_${this.customGoalInteraction}`;
      const customGoalHoldouts = this.holdoutStatsItem.custom_goals_holdout || 0;
      return this.sumStats(statType, true) + customGoalHoldouts;
    },
    totalGoals2() {
      return this.sumStats("goals2");
    },
    totalCustomGoalsB2() {
      return this.sumStats("custom_goals_b2", true);
    },
    goalVal() {
      return !this.hasCustomGoal ? "goals" : `custom_goals_${this.customGoalInteraction}`;
    },
    goalVal2() {
      return this.hasCustomGoal ? "custom_goals_b2" : "goals2";
    },
    chartData() {
      const currentMetric = this.metrics[this.metricsKey];
      if (!currentMetric) return null;
      const findFirstMetricName = currentMetric.name;
      const data = currentMetric.data.filter(item => !(!item.is_visible && item.is_control));
      const impressionsKey = !this.hasCustomGoal
        ? "uimpressions"
        : interactionDenominators[this.customGoalInteraction];
      const goalsName = "conv. rate %";
      let header = [findFirstMetricName];
      header = header.concat(data.map(item => `${item.name} - ${goalsName}`));
      if (this.accept2Enabled && !this.hasCustomGoal) {
        header = header.concat(data.map(item => `${item.name} - ${goalsName} (additional)`));
      }

      const rows = [];

      const dates = data[0][impressionsKey].data.map(item => item.date);
      dates.forEach((date, index) => {
        let label = MetricsUtils.metricDateTimeFormatter(
          this.metricsKey,
          date,
          this.singleDayCustom,
        );

        if (
          this.metricsKey === "today" ||
          this.metricsKey === "yesterday" ||
          this.singleDayCustom
        ) {
          const isTimeShown = (index + 1) % 4 === 1;
          label = isTimeShown ? label : "";
        }

        const percentages = [];
        const goalTypes = [this.goalVal];
        if (this.accept2Enabled && !this.hasCustomGoal) goalTypes.push(this.goalVal2);

        goalTypes.forEach(name => {
          data.forEach(item => {
            const impressions = item[impressionsKey] && item[impressionsKey].data[index].count; // || Math.ceil(Math.random() * 100);
            const clicks = item[name] && item[name].data[index].count; // || Math.ceil(Math.random() * 100);

            if (!impressions && impressions !== 0) {
              percentages.push(null);
            } else {
              let numerator = clicks;

              if (numerator > impressions) numerator = impressions;
              percentages.push(percentage(numerator, impressions));
            }
          });
        });

        rows.push([label].concat(percentages));
      });
      return [header, ...rows];
    },
    chartOptions() {
      const tableWidth = "100%";
      const tableHeight = 420;
      const opts = {
        chartArea: {
          left: 50,
          top: 25,
          width: "94%",
          height: "82%",
        },
        width: tableWidth,
        height: tableHeight,
        animation: {
          duration: 1000,
          easing: "inAndOut",
          startup: true,
        },
        backgroundColor: { fill: "transparent" },
        legend: { position: "top" },
        focusTarget: "category",
        tooltip: { isHtml: "true" },

        colors: ["#4D96E6", "#9DC9F7", "#234569", "#245897", "#CBE3FC", "#63CCFF"],
        curveType: "function",
        pointSize: 10,
        vAxes: {
          0: {
            title: "Conv. rate %",
            viewWindow: {
              min: 0,
              max: this.getMaxCTRChartValue,
            },
            baselineColor: "#cccccc",
            gridlines: {
              color: "#eee",
              count: 4,
            },
            minorGridlines: {
              color: "#eeeeee",
              count: 0,
            },
          },
        },
        bar: { groupWidth: "80%" },
        seriesType: "bars",
        series: this.getLineSeriesOptions,
      };
      if (this.metricsKey === "this_month" || this.metricsKey === "last_month") {
        opts.hAxis = { maxAlternation: 1, showTextEvery: 2 };
      }
      return opts;
    },
    getLineSeriesOptions() {
      const currentMetric = this.metrics[this.metricsKey];
      if (!currentMetric) return {};
      const { data } = currentMetric;

      let lineCount = data.length - 1;
      if (this.accept2Enabled && !this.hasCustomGoal) lineCount *= 2;

      const colorpalette = ["#4D96E6", "#9DC9F7", "#234569", "#245897", "#CBE3FC", "#63CCFF"];

      const opts = {};
      let colorindex = 0;
      for (let i = 0; i < lineCount; i++) {
        opts[i] = {
          type: "line",
          curveType: "function",
          title: "CTR",
          format: "# $",
          color: colorpalette[colorindex],
          targetAxisIndex: 0,
        };
        colorindex += 1;
      }
      return opts;
    },
    getMaxCTRChartValue() {
      const currentMetric = this.metrics[this.metricsKey];
      if (!currentMetric) return 100;
      const metricValues = currentMetric.data.reduce((total, item) => {
        const impr = item.impressions.data.map(i => i.count);
        let clicks = item[this.goalVal]?.data?.map(i => i.count) || 0;
        if (this.accept2Enabled && !this.hasCustomGoal) {
          clicks = clicks.concat((item[this.goalVal2].data || []).map(i => i.count));
        }
        const ctr = clicks.map((click, index) => Math.ceil((click / (impr[index] || 1)) * 100));
        return total.concat(ctr);
      }, []);

      const chartCeilingRounder = 10;
      const value = Math.max(...metricValues, chartCeilingRounder);
      const maxValue = Math.ceil(value / chartCeilingRounder) * chartCeilingRounder * 1.5;
      if (maxValue < 100) {
        return maxValue;
      }
      return 100;
    },
    getMaxClickChartValue() {
      const currentMetric = this.metrics[this.metricsKey];
      if (!currentMetric) return 100;
      const metricValues = currentMetric.data.reduce((total, item) => {
        const clicks = item[this.goalVal].data.map(i => i.count);
        return total.concat(clicks);
      }, []);

      const chartCeilingRounder = 10;
      const value = Math.max(...metricValues, chartCeilingRounder);
      return Math.ceil(value / chartCeilingRounder) * chartCeilingRounder * 1.5;
    },
    isExperimentStarted() {
      return !!Object.keys(this.metrics).length;
    },
    holdoutStatsItem() {
      if (!this.runningStat) return {};
      return this.runningStat.find(item => item.is_control) || {};
    },
    baselineStatsItem() {
      if (!this.runningStat) return {};
      return this.runningStat.find(item => item.is_baseline) || {};
    },
    holdoutVariant() {
      if (!this.currExperiment || !this.currExperiment.variations) return null;

      return this.currExperiment.variations.find(item => item.is_control);
    },
    holdoutTraffic() {
      if (this.holdoutVariant) return this.holdoutVariant.traffic_percent;

      return 0;
    },
    totalConversionRate() {
      if (this.hasCustomGoal) return this.displayConversionRate;
      const uimpressions = this.sumStats("uimpressions");
      if (uimpressions) {
        const goals = this.totalGoals;
        return percentage(goals, uimpressions, true);
      }
      return "N/A";
    },
    totalConversionRate2() {
      let uimpressions;
      let goals;
      if (this.hasCustomGoal) {
        goals = this.totalCustomGoalsB2;
        uimpressions = this.totalUsers;
      } else {
        uimpressions = this.sumStats("uimpressions");
        goals = this.totalGoals2;
      }
      if (uimpressions) return percentage(goals, uimpressions, true);
      return "N/A";
    },
    totalCTR2() {
      return this.totalImpressions
        ? percentage(this.totalGoals2, this.totalImpressions, true)
        : "N/A";
    },
    pieMetrics() {
      if (!this.metrics || !this.metricsKey || this.isInvisible) return [];

      const actionGroupList = this.pieActionGroupId
        ? [{ id: this.pieActionGroupId }]
        : this.variations;

      const pieData = actionGroupList.reduce((acc, variation) => {
        const counts = this.getActionCounts(variation.id);
        counts.forEach(([keyRaw, count]) => {
          const key = this.namingMapperMetrics(keyRaw);
          acc[key] = count.uniques + (acc[key] || 0);
        });
        return acc;
      }, {});

      return [TITLE_ROW, ...Object.entries(pieData).filter(([_, value]) => value)];
    },
    pieActionGroupName() {
      if (!this.pieActionGroupId) return "Total";
      const variant = this.variations.find(v => v.id === this.pieActionGroupId);
      return variant.name;
    },
    chartPieWrapperClassName() {
      return this.pieMetrics && this.pieMetrics.length > 1 ? "chartdata-pie-sidebyside" : "";
    },
  },
  methods: {
    ...mapActions(["runExperimentWithVariant", "updatePath"]),
    getActionCounts(actionGroupId) {
      const actionGroupMetrics =
        this.metrics[this.metricsKey] &&
        this.metrics[this.metricsKey].data &&
        this.metrics[this.metricsKey].data.find(el => el.id === actionGroupId);

      if (!actionGroupMetrics) return [];
      return Object.entries(actionGroupMetrics).filter(this.onlyDisplayedMetrics);
    },
    async onWinner(winnerId, pause) {
      const winnerVariation = this.variations.find(item => item.id === winnerId);
      const appId = this.$route.params.aid;
      const pathId = this.model.id;
      await this.runExperimentWithVariant({
        appId,
        pathId,
        expId: this.currExperiment.id,
        variation: winnerVariation,
      });
      if (pause) {
        const modelPath = cloneDeep(this.model);
        modelPath.is_enabled = false;
        this.updatePath({ appId, pathId, modelPath })
          .then(() => null)
          .catch(() => null);
      }
    },
    confirmModal(item) {
      this.confirmDialog = true;
      this.variantItem = item;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.variantItem = {};
    },
    formatUsers(item) {
      let stats = item.uimpressions;
      if (item.is_control) {
        stats = item.uholdouts;
      }
      return this.formatStatsLocale(stats);
    },
    formatImpressions(item) {
      if (item.is_control) return "-";
      return this.formatStatsLocale(item.impressions);
    },
    formatGoals(item) {
      if (item.is_control)
        return this.isInvisible && item.uholdouts ? toLocaleNumberString(item.uholdouts) : "-";
      const stats = !this.hasCustomGoal
        ? item.goals
        : item[interactionDenominators[this.customGoalInteraction]];

      return this.formatStatsLocale(stats);
    },
    formatCustomGoals(item) {
      const stats = this.getCustomGoals(item);
      return this.formatStatsLocale(stats);
    },
    getCustomGoals(item) {
      let stats;
      if (item.is_control) {
        stats = item.custom_goals_holdout;
      } else {
        stats = item[`custom_goals_${this.customGoalInteraction}`];
      }
      return stats;
    },
    formatGoals2(item) {
      if (item.is_control) return "-";
      const stats = item.goals2;
      return this.formatStatsLocale(stats);
    },
    formatConversion(item) {
      if (item.is_control && !this.hasCustomGoal) return "-";
      if (!this.hasCustomGoal) return this.formatStatsFixed(item.conversion_rate);
      const numerator = this.getCustomGoals(item) || 0;
      let denomAttr = "uimpressions";
      if (item.is_control) denomAttr = "uholdouts";
      const denominator = item[denomAttr] || 0;
      return this.formatStatsFixed(percentage(numerator, denominator));
    },
    formatConversion2(item) {
      if (item.is_control && !this.hasCustomGoal) return "-";
      return this.formatStatsFixed(item.conversion_rate2);
    },
    formatCTR(item) {
      if (item.is_control) return "-";

      if (this.customGoalInteraction === "accepted2") return this.formatCTR2(item);
      return this.formatStatsFixed(item.ctr);
    },
    formatCTR2(item) {
      return item.is_control ? "-" : this.formatStatsFixed(item.ctr2);
    },
    formatStatsLocale: stat => toLocaleNumberString(stat),
    closeCustomDateRange() {
      this.metricsKey = "last_seven_days";
    },
    fetchMetrics(timePeriod) {
      this.singleDayCustom = false;
      if (timePeriod === "since_start_date" && this.currExperiment?.start_date) {
        const now = new Date();
        const datestring = `${now.getFullYear()}-${`0${now.getMonth() + 1}`.slice(
          -2,
        )}-${`0${now.getDate()}`.slice(-2)}`;
        this.singleDayCustom = datestring === this.currExperiment.start_date.slice(0, 10);
      }
      if (!Object.entries(this.metrics).find(([_, v]) => v[timePeriod])) {
        this.$emit("fetchMetrics", { metric_periods: [{ period: timePeriod }] });
      }
      this.metricsKey = timePeriod;
    },
    fetchMetricsCustom(timePeriod) {
      if (timePeriod) {
        this.singleDayCustom = timePeriod[0] === timePeriod[1];
        this.$emit("fetchMetrics", {
          metric_periods: [{ period: "custom", date_range: timePeriod }],
        });
      }
      this.metricsKey = "custom";
    },
    sumStats(key, includeControl) {
      if (this.variations) {
        const count = this.runningStat.reduce((accum, item) => {
          if (item.is_control && !includeControl) return accum;
          return accum + item[key];
        }, 0);
        return count;
      }
      return "";
    },
    totalStatByKey(item, key) {
      const variation = this.variations.find(v => v.id === item.variant.id);
      if (!variation || !variation.stats) return "";
      return variation.stats[key];
    },
    showVariant(item) {
      const variant = this.currExperiment.variations.find(v => v.id === item.id);
      this.$emit("showVariantDetails", variant);
    },
    setPieVariant(item) {
      if (!item.is_control) this.$emit("update:pieActionGroupId", item.variant.id);
    },
    conversionRate(item) {
      let numerator = item.goals.uniques;
      if (this.hasCustomGoal) numerator = (item.custom_goals && item.custom_goals.uniques) || 0;

      const denominator = this.conversionDenominator(item);

      return percentage(numerator, denominator);
    },
    conversionRate2(item) {
      let numerator = item.goals2.uniques;
      if (this.hasCustomGoal)
        numerator = (item.custom_goals_b2 && item.custom_goals_b2.uniques) || 0;
      const denominator = this.conversionDenominator(item);

      return percentage(numerator, denominator);
    },
    conversionDenominator(item) {
      let denominator = item.uimpressions.uniques;
      if (this.hasCustomGoal && item.is_control) denominator = item.uholdouts.uniques;
      return denominator;
    },
  },
};
</script>

<style scoped>
.rf-exp-graph-variant {
  text-align: left;
  width: 100%;
  height: 100%;
}
.col-exp-1 {
  min-width: 200px;
}
.col-width-1a {
  min-width: 110px;
}
</style>
