<template>
  <div>
    <v-radio-group v-model="domain" style="margin-top: 0">
      <v-radio v-for="(alias, index) in aliases" :key="index" :value="alias" :label="alias" />
    </v-radio-group>
    <v-text-field
      outlined
      persistent-hint
      placeholder="/account"
      label="Path"
      hint="Optional path that gets appended to selected domain above"
      v-model.trim="path"
      name="path"
    >
    </v-text-field>
    <v-switch v-model="debug" color="primary" label="Debug mode" v-if="liveV2"></v-switch>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RfLiveDomainChooser",
  props: ["app"],
  data() {
    return {
      domain: this.app.domain,
      path: null,
      debug: false,
    };
  },
  methods: {
    getDomain() {
      let { domain } = this;

      if (this.path && !this.path.match(/^\//)) this.path = `/${this.path}`;

      if (this.path) domain += this.path;
      // debug flag to support existing conduit based live tool
      if (this.liveV2) domain += `?debug=${this.debug}`;

      return domain;
    },
  },
  computed: {
    ...mapState({
      currUser: state => state.apps.currUser,
    }),
    aliases() {
      if (this.app) {
        const result = [this.app.domain];
        return result.concat(this.app.domain_aliases || []);
      }
      return [];
    },
    liveV2() {
      return this.app.feature_set.includes("live_v2");
    },
  },
};
</script>
