<template>
  <Transition mode="out-in">
    <div
      v-if="!props.skeleton && hasData"
      key="chart--data"
      class="rf-prompt-data--chart-wrapper -mt-px box-content flex w-full min-w-px max-w-full flex-col pt-6"
    >
      <div class="rf-prompt-data--height relative">
        <canvas ref="chart" class="rf-prompt-data--height absolute inset-0"></canvas>
      </div>
      <div
        v-if="!props.noLegend"
        class="rf-prompt-chart--legend inline-flex h-6 justify-between gap-2 pt-2"
      >
        <slot v-if="$slots.default || $scopedSlots.default" />
        <span v-else></span>
        <div v-show="showLegend" class="rf-prompt-chart--legend-container"></div>
      </div>
    </div>
    <div
      v-else-if="!props.skeleton && !hasData"
      key="chart--empty"
      class="box-content flex h-64 py-2"
    >
      <span class="text-action-buttons m-auto font-bold"> There is no data. </span>
    </div>
    <div
      v-else
      class="box-content flex flex-col pt-6"
      key="chart--load"
      :class="{ 'pb-8': props.noLegend }"
    >
      <div class="flex items-start">
        <div class="rf-prompt-chart--chart flex items-start gap-5">
          <div class="flex flex-grow-0 flex-col items-end gap-6">
            <RfBaseSkeleton v-for="i in 5" :key="i" width="30" height="16" />
            <RfBaseSkeleton width="20" height="16" />
          </div>
          <div class="flex w-px flex-col">
            <div
              class="bg-stripes mt-2 flex h-52 flex-grow gap-2 before:border-b-2 before:border-solid before:border-b-grey-1"
            >
              <div
                v-for="i in 6"
                :key="i"
                class="flex h-52 flex-col items-center justify-end gap-3"
                style="margin-top: 26px"
              >
                <RfBaseSkeleton
                  height="100"
                  dynamicHeight
                  rangeHeight="50"
                  class="!w-full !max-w-24 !rounded-b-none"
                />
                <RfBaseSkeleton width="40" height="16" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!props.noLegend" class="flex h-6 items-center justify-end gap-6 pt-10">
        <div v-for="i in 6" class="inline-flex items-center gap-2">
          <RfBaseSkeleton height="24" width="24" />
          <RfBaseSkeleton height="10" width="50" dynamic />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed, onUnmounted, ref, watchEffect } from "vue";
import { useBarChart } from "@/utils/composables/useBarChart";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import { newChartHasData } from "@/utils/metricsHelpers";

const props = defineProps({
  skeleton: { type: Boolean, default: false },
  data: { type: Array, default: () => [] },
  noLegend: { type: Boolean, default: false },
  hasData: { type: [Boolean, undefined], default: undefined },
  selectedChartKey: { type: Number, default: undefined },
});

const chart = ref(null);
const destroyChart = ref(() => null);

const showLegend = computed(() => props.data?.length > 1 && !props.selectedChartKey);
const hasData = computed(() =>
  typeof props.hasData === "boolean" ? props.hasData : newChartHasData(props.data),
);

watchEffect(() => {
  if (!props.skeleton && hasData.value && chart.value)
    destroyChart.value = useBarChart(
      chart,
      props.selectedChartKey ? [props.data[props.selectedChartKey - 1]] : props.data,
    );
});

onUnmounted(destroyChart?.value);

defineExpose({ hasData });
</script>

<style scoped>
.rf-prompt-data--height {
  height: 249px;
  max-height: 249px;
  min-height: 249px;
}

.bg-stripes {
  position: relative;
  background: repeating-linear-gradient(
    0deg,
    #c9ddf9 0px,
    #c9ddf9 1px,
    transparent 1px,
    transparent 40px
  );
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(
      90deg,
      white 4px,
      white 8px,
      transparent 8px,
      transparent 13px
    );
  }
}
</style>
