import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiPaths {
  static createPath(appId, path) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(path),
    });
  }

  static getPrompts(appId, params = {}) {
    const query = Object.assign({ metric_periods: [{ period: "last_seven_days" }] }, params);
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths?${serializeQuery(query)}`);
  }

  static getRetentions(appId, params) {
    let query = serializeQuery(params);
    if (query) query = `&${query}`;
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths?path_type=retention_modal,video,horizontal,vertical,tile,text,invisible,widget,interstitial,email,bottom_banner${query}`,
    );
  }

  static getPath(appId, pathId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}`);
  }

  static updatePath(appId, pathId, path) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(path),
    });
  }

  static uploadBgImageAction(file, action, appId, pathId) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/${pathId}/upload_background_image_action`,
      {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image: {
            action_name: action,
            image_file: file,
          },
        }),
      },
    );
  }

  static deletePath(appId, pathId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}`, {
      method: "DELETE",
    }).then(_ => pathId);
  }

  static getActivities(appId, pathId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/activity`, {
      method: "GET",
    });
  }

  static getVideoUploadUrl(appId, pathId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/video_upload_url`, {
      method: "GET",
    });
  }

  static setPriorityRetentions(appId, pathGroups) {
    const prioritizedList = pathGroups.reduce((h, pathGroup) => {
      return {
        ...h,
        [pathGroup.id]: { priority: pathGroup.priority },
      };
    }, {});
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/set_priority?path_type=retention_modal,video,horizontal,vertical,tile,text,invisible,widget,interstitial`,
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ path_groups: prioritizedList }),
      },
    );
  }

  static setOrderRetentions(appId, paths) {
    const prioritizedList = paths.reduce((h, path) => {
      return {
        ...h,
        [path.id]: { order: path.order },
      };
    }, {});
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/set_order?path_type=horizontal,vertical,tile,text,invisible,interstitial,email,bottom_banner`,
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ paths: prioritizedList }),
      },
    );
  }

  static setPriority(appId, paths) {
    const prioritizedList = paths.reduce((h, path) => {
      return {
        ...h,
        [path.id]: { priority: path.priority },
      };
    }, {});
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/set_priority?path_type=custom`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paths: prioritizedList }),
    });
  }

  static getRetentionModel(
    appId,
    pathType,
    deviceType,
    iab,
    rfLitePathId,
    customDeviceIds = [],
    sequenceId,
  ) {
    const params = new URLSearchParams();
    if (pathType) params.append("path_type", pathType);
    if (deviceType) params.append("device_type", deviceType);
    if (iab) params.append("iab", iab);
    if (rfLitePathId) params.append("rf_lite_path_id", rfLitePathId);
    if (customDeviceIds) params.append("custom_device_ids", customDeviceIds);
    if (sequenceId) params.append("sequence_id", sequenceId);
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/new?${params.toString()}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  static retryAndDeleteConnectorError(appId, pathId, connectorError) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/${pathId}/retry_and_delete_connector_error`,
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ connector_error: connectorError }),
      },
    );
  }

  static deleteConnectorError(appId, pathId, connectorError) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/delete_connector_error`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ connector_error: connectorError }),
    });
  }

  static clonePathToApp(currAppId, pathId, targetAppId, defaultCompany) {
    const endpoint = defaultCompany ? "clone_to_app" : "clone_to_company";
    const query = serializeQuery({ clone_app_id: targetAppId });
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${currAppId}/paths/${pathId}/${endpoint}?${query}`,
      {
        method: "POST",
        headers: { "Accept": "application/json", "Content-Type": "application/json" },
      },
    );
  }

  static clonePath(appId, pathId, pathGroupId, sequenceId) {
    const body = {};
    if (pathGroupId) body.path_group_id = pathGroupId;
    if (sequenceId) body.sequence_id = sequenceId;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/clone`, {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
  }

  static cloneRfLitePaths(appId, params) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/rf_lite_clone`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
  }

  static resetGoals(appId, pathId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/reset_goals`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static updateRecentActivity(appId, pathId, userId, timestamp) {
    const body = {
      recent_activity: {
        user_id: userId,
        timestamp,
      },
    };
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/recent_activity`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  static getRecentPromos(appId, params) {
    let query = serializeQuery(params);
    if (query) query = `&${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/recent_paths?${query}`);
  }

  static sendTestEmail(appId, pathId, recipient, actionGroupId) {
    const params = {
      recipient_email: recipient,
    };
    if (actionGroupId) params.action_group_id = actionGroupId;
    const query = serializeQuery(params);
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/${pathId}/send_test_email?${query}`,
    );
  }

  static livePaths(appId, pathIds, language) {
    if (!pathIds || !pathIds.length)
      throw new Error("Expected second parameter to be a non empty array");

    const query = serializeQuery({ path_ids: pathIds.join(), ...(language && { language }) });
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/live?${query}`);
  }

  static getLiveData(appId, pathId, params) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/live_feed${query}`, {
      method: "GET",
      headers: { Accept: "application/json" },
    });
  }

  static bulkUpdate(appId, paths) {
    if (!paths?.length) throw new Error("Expected second parameter to be a non empty array");

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/bulk_update`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paths }),
    });
  }

  static bulkDelete(appId, paths) {
    if (!paths?.length) throw new Error("Expected second parameter to be a non empty array");

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/bulk_delete`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paths }),
    });
  }
}
