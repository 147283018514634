export default {
  computed: {
    message() {
      return this.actions.rf_retention_message;
    },
    mobileMessage() {
      return this.actions.rf_mobile_message;
    },
    fillColor() {
      return this.actions.rf_settings_fill_color;
    },
    acceptButtonStyle() {
      return {
        "color": this.actions.button1_text_color,
        "background-color": this.actions.button1_bg_color,
      };
    },
    secondaryButtonStyle() {
      return {
        "color": this.actions.button2_text_color,
        "background-color": this.actions.button2_bg_color,
      };
    },
    cancelButtonStyle() {
      const result = {
        color: this.actions.button3_text_color,
      };
      if (
        this.model &&
        ["ios", "android_os"].includes(this.model.device_type) &&
        this.model.path_type === "interstitial"
      )
        result["background-color"] = this.actions.button3_bg_color;
      return result;
    },
    closeButtonEnabled() {
      return this.actions.rf_settings_close_button_enabled === "true";
    },
  },
};
