<template>
  <div>
    <div class="promo-design-inputs">
      <v-expansion-panels
        v-if="
          !actionGroup &&
          !model.experiment &&
          model.path_type !== PromptTypes.scheduled_push.value &&
          !isNewDeviceInterstitial
        "
        v-model="getTopPanel"
        multiple
        focusable
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Localization</v-expansion-panel-header>
          <v-expansion-panel-content class="retention-config-segments">
            <v-radio-group v-model="model.filter.is_translated">
              <v-radio label="Localized" :value="true"></v-radio>
              <v-radio label="Not Localized" :value="false"></v-radio>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="isLocalized">
          <v-expansion-panel-header>Languages</v-expansion-panel-header>
          <v-expansion-panel-content>
            <RfPromoLanguages
              :model="model"
              :actionGroup="actionGroup"
              :viewTypeTab="viewTypeTab"
              v-on:forceParentRender="forceParentRender"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-if="isModalOrVideo && canConfigure">
        <v-expansion-panels
          v-model="getPromoPanel"
          multiple
          focusable
          v-if="viewTypeTab === 'desktopViewTab'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>Message</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>Size</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionWindowSize
                :model="model"
                :actions="actions"
                :showPopUpSize="isNewDeviceEditor"
                :isNewAndroidTv="isNewAndroid"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>User Interaction</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionButtons :actions="actions" :model="model" :showCloseOptions="true" />
              <RfRetentionBehavior
                :model="model"
                :actions="actions"
                :showCloseOptions="false"
                :clickOutsideSupported="true"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.device_type === 'web'">
            <v-expansion-panel-header>Form</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionFieldSet :model="model" :actions="actions" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.device_type === 'web'">
            <v-expansion-panel-header>Countdown Timer</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsCountdown :model="model" :actions="actions" :app="app" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Close Timer</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsBehavior
                :model="model"
                :actions="actions"
                :hideAutoCloseText="isNewDeviceEditor"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.path_type !== 'video'">
            <v-expansion-panel-header>Background</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionImageUpload
                :actions="actions"
                :model="model"
                v-on:forceParentRender="forceParentRender"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.path_type === 'video'">
            <v-expansion-panel-header>Video</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionVideoUpload :model="model" :actions="actions" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!isMobileAndTvAppAndAndroid && model.path_type !== 'video'">
            <v-expansion-panel-header>Legal</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsLinks :model="model" :actions="actions" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Effect</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfBorderEffects
                :actions="actions"
                :transparency="!isMobileAndTvAppAndAndroidAndCustomDevices"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>CSS</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <div class="promo-design-group">
                <RfPromoCustomCSS :actions="actions" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="hasInapp">
            <v-expansion-panel-header>In-App Purchase</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsInapp :actions="actions" :app="app" :model="model" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.device_type !== 'web'">
            <v-expansion-panel-header>Deep Link</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsDeeplink :model="model" :actions="actions" :app="app" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.device_type !== 'web'">
            <v-expansion-panel-header>Device Metadata</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsMetadata :model="model" :actions="actions" :app="app" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels
          v-model="getPromoPanel"
          multiple
          focusable
          v-if="viewTypeTab === 'mobilewebViewTab'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>CONFIGURATION</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionActionsMobileWeb :app="app" :model="model" :actions="actions" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="isNewDeviceEditor">
            <v-expansion-panel-header>
              <span>Size</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfRetentionWindowSize :model="model" :actions="actions" showPopUpSize />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Effect</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfBorderEffects :actions="actions" mobile />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>CSS</v-expansion-panel-header>
            <v-expansion-panel-content class="retention-config-segments">
              <RfPromoCustomCSS :actions="actions" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </div>
    <RfRetentionActionsWidget
      :model="model"
      :actions="actions"
      :app="app"
      v-if="isWidget && canConfigure"
      :viewTypeTab="viewTypeTab"
      v-on:forceParentRender="forceParentRender"
    />
    <RfRetentionActionsBanner
      v-if="isBanner && canConfigure"
      :model="model"
      :actions="actions"
      :app="app"
      :viewTypeTab="viewTypeTab"
      @updateBannerPosition="p => (actions.rf_widget_position = p)"
      @updateMobileBannerPosition="p => (actions.rf_mobile_widget_position = p)"
    />
    <RfRetentionActionsInterstitial
      :model="model"
      :actions="actions"
      :app="app"
      v-if="isInterstitial && canConfigure"
      :viewTypeTab="viewTypeTab"
      v-on:forceParentRender="forceParentRender"
    />
    <RfRetentionActionsInlinePromo
      :model="model"
      :actions="actions"
      :app="app"
      v-if="isHorizontalVerticalTile && canConfigure"
      :viewTypeTab="viewTypeTab"
      v-on:forceParentRender="forceParentRender"
    />
    <RfRetentionActionsTextPromo
      :model="model"
      :actions="actions"
      :app="app"
      v-if="model.path_type === 'text' && canConfigure"
      :viewTypeTab="viewTypeTab"
    />
    <RfRetentionActionsEmailPromo
      v-if="isEmail(model)"
      :model="model"
      :actions="actions"
      :app="app"
    />
    <RfRetentionActionsPushPromo
      v-if="isPushNotification(model)"
      :model="model"
      :actions="actions"
      :app="app"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfPromoLanguages from "@/components/RfPromotions/RfPromoLanguages.vue";
import RfRetentionActionsBanner from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBanner.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";
import RfRetentionActionsDeeplink from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsDeeplink.vue";
import RfRetentionActionsEmailPromo from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsEmailPromo.vue";
import RfRetentionActionsInapp from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInapp.vue";
import RfRetentionActionsInlinePromo from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInlinePromo.vue";
import RfRetentionActionsInterstitial from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInterstitial.vue";
import RfRetentionActionsLinks from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsLinks.vue";
import RfRetentionActionsMetadata from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMetadata.vue";
import RfRetentionActionsCountdown from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsCountdown.vue";
import RfRetentionActionsMobileWeb from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMobileWeb.vue";
import RfRetentionActionsTextPromo from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTextPromo.vue";
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionActionsWidget from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsWidget.vue";
import RfRetentionBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionBehavior.vue";
import RfRetentionButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtons.vue";
import RfRetentionFieldSet from "@/components/RfPathCreate/RfRetentionActions/RfRetentionFieldSet.vue";
import RfRetentionImageUpload from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionImageUpload.vue";
import RfRetentionVideoUpload from "@/components/RfPathCreate/RfRetentionActions/RfRetentionVideoUpload.vue";
import RfRetentionWindowSize from "@/components/RfPathCreate/RfRetentionActions/RfRetentionWindowSize.vue";
import { cloneDeep } from "lodash-es";
import RfDeviceApiPayloadModal from "../../RfDeviceApiPayloadModal.vue";
import { isEmail, isPushNotification } from "@/utils/prompts/promptHelpers";
import RfRetentionActionsPushPromo from "./RfRetentionActionsPushPromo.vue";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";
import { DefaultDeviceOptions, DeviceTypeWording } from "@/utils/constants/DevicesConstants";
import RfBorderEffects from "./RfBorderEffects.vue";

export default {
  name: "RfRetentionActionsInputs",
  components: {
    RfPromoCustomCSS,
    RfPromoLanguages,
    RfRetentionActionsBanner,
    RfRetentionActionsBehavior,
    RfRetentionActionsDeeplink,
    RfRetentionActionsEmailPromo,
    RfRetentionActionsInapp,
    RfRetentionActionsInlinePromo,
    RfRetentionActionsInterstitial,
    RfRetentionActionsLinks,
    RfRetentionActionsMetadata,
    RfRetentionActionsMobileWeb,
    RfRetentionActionsTextPromo,
    RfRetentionActionsTitleMessage,
    RfRetentionActionsWidget,
    RfRetentionBehavior,
    RfRetentionButtons,
    RfRetentionFieldSet,
    RfRetentionImageUpload,
    RfRetentionVideoUpload,
    RfRetentionWindowSize,
    RfDeviceApiPayloadModal,
    RfRetentionActionsPushPromo,
    RfRetentionActionsCountdown,
    RfBorderEffects,
  },
  setup: () => ({ isEmail, isPushNotification, PromptTypes }),
  mixins: [LanguagePreviewMixin],
  props: ["actions", "model", "viewTypeTab", "actionGroup"],
  data() {
    return {
      previewKey: 0,
      getPromoPanel: [0],
      getTopPanel: [0],
    };
  },
  computed: {
    ...mapState({ app: state => cloneDeep(state.apps.currApp) }),
    isNewApple() {
      return (
        !this.currApp?.flags?.legacy_apple_editor &&
        [DefaultDeviceOptions.tv_os.value, DefaultDeviceOptions.ios.value].includes(
          this.model.device_type,
        )
      );
    },
    isNewAndroid() {
      return (
        this.app?.flags?.new_android_editor &&
        this.model.device_type === DefaultDeviceOptions.android_os.value
      );
    },
    isNewDeviceInterstitial() {
      return (
        ["android_os", "ios"].includes(this.model.device_type) &&
        this.model.path_type === "interstitial"
      );
    },
    isNewDeviceEditor() {
      return this.isNewAndroid || this.isNewApple;
    },
    hasInapp() {
      return [
        DefaultDeviceOptions.roku_os.value,
        DefaultDeviceOptions.ios.value,
        DefaultDeviceOptions.android_os.value,
      ].includes(this.model.device_type);
    },
    isTVapp() {
      const devices = [
        DeviceTypeWording.roku_os.value,
        DeviceTypeWording.tv_os.value,
        DeviceTypeWording.android_os.value,
        DeviceTypeWording.custom_defined.value,
      ];
      return devices.includes(this.model.device_type);
    },
    isMobileAndTvAppAndAndroid() {
      const devices = [
        DeviceTypeWording.roku_os.value,
        DeviceTypeWording.tv_os.value,
        DeviceTypeWording.ios.value,
      ];
      if (this.app?.flags?.new_android_editor) devices.push(DeviceTypeWording.android_os.value);
      return devices.includes(this.model.device_type);
    },
    isMobileAndTvAppAndAndroidAndCustomDevices() {
      return (
        this.isMobileAndTvAppAndAndroid ||
        this.model.device_type === DeviceTypeWording.custom_defined.value
      );
    },
    isModalOrVideo() {
      const pathType = this.model.path_type;
      return pathType === "retention_modal" || pathType === "video";
    },
    isInterstitial() {
      return this.model.path_type === "interstitial";
    },
    isWidget() {
      return this.model.path_type === "widget";
    },
    isBanner() {
      return this.model.path_type === "bottom_banner";
    },
    isHorizontalVerticalTile() {
      const pathType = this.model.path_type;
      return pathType === "horizontal" || pathType === "vertical" || pathType === "tile";
    },
    imageSizeHelpText() {
      if (this.isTVapp) return "16:9 image, 1920 x 1080 px recommended";
      return "2400 x 1600 px recommended";
    },
  },
  methods: {
    forceParentRender() {
      this.$emit("forceRender");
    },
  },
};
</script>
