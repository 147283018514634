<template>
  <div class="trigger-args-container">
    <template v-if="!fieldData || argPairs.length < fieldData.length">
      <div class="trigger-args-add-btn">
        <v-btn class="mt-1" icon small color="primary" @click="addArgRow">
          <v-icon size="22">add</v-icon>
          Add argument
        </v-btn>
      </div>
      <hr class="trigger-args-divider" />
    </template>
    <RfAdvancedArgumentsRow
      v-for="(_, index) in argPairs"
      ref="advancedArgumentsRows"
      :showFieldData="fieldData && fieldData.length"
      :fieldData="getFieldData(index)"
      :fieldDataValues="getFieldDataValues(argPairs[index][0])"
      :fieldDataPair0.sync="argPairs[index][0]"
      :fieldDataPair1.sync="argPairs[index][1]"
      @delete="deleteArgRow(index)"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import RfAdvancedArgumentsRow from "./RfAdvancedArgumentsRow.vue";

const props = defineProps({ loadedArgs: { type: Object, default: () => ({}) } });
const argPairs = ref([]);
const fieldData = ref([]);
const advancedArgumentsRows = ref([]);

const initArgs = (args, data) => (
  (argPairs.value = Object.entries(args || props.loadedArgs || {})), (fieldData.value = data)
);
const addArgRow = () => argPairs.value.push(["", ""]);
const deleteArgRow = index => argPairs.value.splice(index, 1);
const getArgs = () =>
  Object.fromEntries(
    argPairs.value.filter(([key, value]) => key && key.length && value && value.length),
  );

const getFieldDataValues = key => fieldData.value?.find(el => el.var === key) || {};
const getFieldData = index =>
  fieldData.value?.filter(
    el => !argPairs.value?.find(([key, _], i) => key === el.var && index !== i),
  );
const hasError = computed(() => !!advancedArgumentsRows.value.find(el => !!el.error));
onMounted(initArgs);

defineExpose({ init: initArgs, get: getArgs, hasError });
</script>
