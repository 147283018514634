import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      realtimeParams: null,
    };
  },
  computed: {
    ...mapState({
      currUser: state => state.apps.currUser,
      currLanguage: state => state.apps.currLanguage,
      idToken: state => state.apps.idToken,
    }),
  },
  methods: {
    ...mapActions(["conduitOverrideActions"]),
    liveUrl(userId, domain, extra = {}) {
      if (!domain) domain = this.currApp.domain;
      domain = domain.replace(/^(https?:|)\/\//, "");

      const params = {
        endUserId: userId,
        appId: this.currApp.id,
        idToken: this.idToken,
        perms: {
          canChangeApp: this.currUser.role === "super_admin",
        },
      };
      if (this.currApp.flags.ai_tool && this.currUser.role === "super_admin") {
        params.ai_tool = true;
      }

      // live v2 params
      const url = new URL(`${window.location.protocol}//${domain}`);

      if (url.search.length) {
        domain = url.host;
        if (url.pathname.length) {
          domain = `${domain}${url.pathname}`;
        }

        const urlParams = new URLSearchParams(url.search);
        extra = { ...extra, ...Object.fromEntries(urlParams) };
      }

      const allParams = { ...params, ...extra };

      this.realtimeParams = `?rf_impersonate=${encodeURIComponent(
        btoa(JSON.stringify(allParams)),
      )}`;

      return `${window.location.protocol}//${domain}/${this.realtimeParams}`;
    },
    openLiveUrl(userId, domain, extra = {}) {
      const url = this.liveUrl(userId, domain, extra);
      window.open(url, "_blank");
    },
    async promoPreviewParams({ actionGroupId, sequenceId }) {
      const body = await this.conduitOverrideActions({ actionGroupId, sequenceId });
      const isMobile = this.viewTypeTab === "mobilewebViewTab";
      let rf_settings_custom_css_scoped = "";

      const language = this.currLanguage === "default" ? "" : `&language=${this.currLanguage}`;
      const variable = sequenceId
        ? `rf_sequence_id=${sequenceId}`
        : actionGroupId
          ? `rf_override_id=${actionGroupId}`
          : null;
      if (!variable) throw Error(`sequence_id or action_group_id is required`);
      let params = `rf_is_preview=true&${variable}${language}`;

      if (isMobile) {
        params += `&rf_is_mobile=${isMobile}&rf_payload_override=${encodeURIComponent(
          JSON.stringify({
            paths: [
              {
                actions: {
                  rf_settings_custom_css_scoped,
                },
              },
            ],
          }),
        )}`;
      }

      return params;
    },
    async viewPreview(domain, { actionGroupId, sequenceId }) {
      const params = await this.promoPreviewParams({ actionGroupId, sequenceId });
      const visitUrl = `//${domain}?${params}`;

      window.open(visitUrl, "_blank");
    },
  },
};
