<template>
  <div class="trigger-args-row align-center">
    <template v-if="props.showFieldData">
      <RfSelect
        :value="fieldDataPair0"
        :items="fieldData"
        item-value="var"
        item-text="label"
        class="flex-shrink-1 mr-2 flex-grow-0"
        dense
        autocomplete
        hide-details
        outlined
        mandatory
        placeholder="Select Field Data variable"
        @input="v => emit('update:fieldDataPair0', v)"
      />
      <RfSelect
        v-if="isDropdown"
        :multiple="isMultiple"
        :value="fieldDataPair1"
        :items="fieldDataValues?.values || []"
        dense
        autocomplete
        class="flex-shrink-1 mr-2 flex-grow-0"
        hide-details
        outlined
        mandatory
        :placeholder="`Select ${fieldDataValues?.label || ''}`"
        @input="v => emit('update:fieldDataPair1', v)"
      />
      <v-text-field
        v-else
        :value="fieldDataPair1"
        outlined
        dense
        hide-details
        placeholder="Value"
        background-color="#ffffff"
        :error.sync="errorValue.bool"
        :error-messages="errorValue.msg"
        @input="v => emit('update:fieldDataPair1', v)"
      />
      <v-tooltip v-if="fieldDataValues.tooltip" top>
        <template #activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon>info</v-icon>
          </v-btn>
        </template>
        <span>{{ fieldDataValues.tooltip }}</span>
      </v-tooltip>
    </template>
    <template v-else>
      <v-text-field
        :value="fieldDataPair0"
        outlined
        dense
        hide-details
        placeholder="Key"
        class="mr-2"
        background-color="#ffffff"
        @input="v => emit('update:fieldDataPair0', v)"
      />
      <v-text-field
        :value="fieldDataPair1"
        outlined
        dense
        hide-details
        placeholder="Value"
        background-color="#ffffff"
        @input="v => emit('update:fieldDataPair1', v)"
      />
    </template>
    <v-btn icon small @click="emit('delete')">
      <v-icon size="22">close</v-icon>
    </v-btn>
  </div>
</template>

<script setup>
import RfSelect from "@/components/inputs/RfSelect.vue";
import { computed, watch } from "vue";

const emit = defineEmits(["delete", "update:fieldDataPair0", "update:fieldDataPair1"]);
const props = defineProps({
  showFieldData: { type: Boolean, default: false },
  fieldData: { type: Array, default: () => [] },
  fieldDataValues: { type: Object, default: () => ({}) },
  fieldDataPair0: { type: String, default: "" },
  fieldDataPair1: { type: String, default: "" },
});

const isDropdown = computed(() => props.fieldDataValues?.type === "dropdown");
const isMultiple = computed(() => props.fieldDataValues?.multiple);

const rules = computed(() => {
  if (!props.fieldDataValues?.regexp) return [];
  if (typeof props.fieldDataValues.regexp === "string") return [props.fieldDataValues.regexp];
  return [];
});

const defaultValue = { bool: false, msg: "" };

const errorValue = computed(
  () =>
    rules.value.map(r => {
      const regexp = !!(props.fieldDataPair1 || "").match(new RegExp(r));
      if (regexp) return defaultValue;
      return { bool: true, msg: `Input does not satisfy ${r}` };
    })?.[0] || defaultValue,
);

const error = computed(() => !isDropdown.value && !!errorValue.value.bool);

watch(
  () => props.showFieldData && props.fieldDataPair0,
  () => emit("update:fieldDataPair1", null),
);

defineExpose({ error });
</script>
