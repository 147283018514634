import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";

export const isRunning = (sequence = {}) => {
  const { is_enabled, start_date, end_date } = sequence;
  return is_enabled && new Date(start_date) < Date.now() && new Date(end_date) > Date.now();
};

export const canReorder = (sequence = {}) =>
  [GUIDE_TYPES.web_client.value, GUIDE_TYPES.ordered.value].includes(sequence.sequence_type);

export const isInvalidSequenceTypeForPromptDevice = (sequence = {}, prompt = {}) =>
  prompt.device_type !== "web" &&
  [GUIDE_TYPES.web_client.value, GUIDE_TYPES.survey.value].includes(sequence.sequence_type);
