<template>
  <div class="goal-configuration-wrapper">
    <div class="rf-section-title">
      <span>Select a goal from below.</span>
    </div>
    <div style="max-width: 600px">
      <v-select
        background-color="#ffffff"
        outlined
        dense
        v-model="selectedGoal"
        :items="usageTrackers"
        label="Available Goals"
      />
      <v-text-field
        label="Attribution duration (hours)"
        v-model="duration"
        type="number"
        outlined
        required
        dense
        hide-details
        background-color="#ffffff"
        v-if="selectedGoal"
      />
      <RfPromoInteractionInterval
        interactionType="customGoal"
        :action="model.actions.rf_settings_custom_goal_interval"
        v-on:setIntervalAction="setIntervalAction"
        style="background-color: #ffffff"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";

export default {
  name: "RfGoal",
  props: ["model"],
  mixins: [CustomFieldsMixin],
  components: { RfPromoInteractionInterval },
  data() {
    return {
      selectedGoal:
        this.model.custom_filter && Object.keys(this.model.custom_filter).length > 0
          ? Object.keys(this.model.custom_filter)[0]
          : "",
      duration: this.model.actions.rf_settings_custom_goal_expire_hours || "48",
    };
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
    }),
    usageTrackers() {
      if (!this.currApp) return [];
      let trackers = [{ value: "", text: "None" }];

      trackers = trackers.concat(
        this.appCustomFields
          .filter(item => {
            return (
              item.connector_type === "redfast" &&
              item.is_usage &&
              ["visits", "minutes"].indexOf(item.label) < 0
            );
          })
          .filter(item => {
            if (this.model.device_type === "web") {
              return item.data_type !== "screen";
            }
            return ["page", "track"].indexOf(item.data_type) < 0;
          })
          .map(item => {
            return { value: item.label, text: item.label };
          }),
      );
      return trackers;
    },
  },
  methods: {
    setIntervalAction(value) {
      this.model.actions.rf_settings_custom_goal_interval = value;
    },
    fillModel(model) {
      model.actions.rf_settings_custom_goal_expire_hours = this.duration;
      if (this.selectedGoal) {
        const options =
          model.custom_filter[this.selectedGoal] && model.custom_filter[this.selectedGoal].options;
        model.custom_filter = {
          [this.selectedGoal]: {
            values: [],
            options,
          },
        };
      } else {
        model.custom_filter = {};
      }
    },
  },
};
</script>
