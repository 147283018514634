<template>
  <div class="rf-detail-content-wrapper experiment-main-wrapper" v-if="path && currExperiment">
    <div class="page-detail-header">
      <span class="my-auto mr-5">
        <v-btn
          class="!shadow-none before:!opacity-0 hover:before:!opacity-20"
          :to="`/apps/${$route.params.aid}/${
            sequenceId
              ? `experiences/${sequenceId}/promotions/${$route.params.pid}`
              : pipelineId
                ? `pipelines/${pipelineId}/promotions/${$route.params.pid}`
                : `retentions/${$route.params.pid}`
          }/experiments`"
          color="white"
          replace
        >
          <v-icon>chevron_left</v-icon>
          Go back
        </v-btn>
      </span>
      <h1>Experiment with {{ path.name }}</h1>
    </div>
    <v-card class="experiment-detail-pg-wrapper">
      <div class="experiment-page-header-div experiment-details-name">
        <h4>Name</h4>
        <span class="d-flex align-center">
          {{ currExperiment.name }}
          <RfButton
            icon="edit"
            color="accent"
            icon-size="16"
            class="ml-1"
            x-small
            :disabled="isDisabledRoleMixin"
            @click="openEditNameDialog"
          />
        </span>
      </div>
      <RfRetentionExperimentDetail
        v-on:onStart="onStart"
        :selectedExperiment="currExperiment"
        :path="path"
        v-on:showTrafficDetail="showTrafficDetail"
        @updateDate="updateExperimentDate"
      />
      <template v-if="!currExperiment.is_active && currExperiment.is_completed">
        <div style="padding-top: 1rem" class="experiment-pg-module experiment-details-mod">
          <h4>Outcome</h4>
          <div class="experiment-details-wrapper-col">
            <RfRetentionExperimentResultTable
              v-on:showVariantDetails="showVariantDetails"
              :selectedExperiment="currExperiment"
              :path="path"
            />
          </div>
        </div>
        <div class="text-body px-4 pb-4">
          *Statistical significance calculations are for informational and illustrative purposes
          only. Raw data is available to you for more advanced analysis.
        </div>
      </template>
    </v-card>
    <v-card
      v-if="
        (isSurvey && currExperiment.is_completed) ||
        (!currExperiment.is_completed && isCurrExperimentRunning)
      "
      class="mb-5 pb-4"
    >
      <div class="experiment-stat-mod-header segment-overview-header">
        <h4>Results</h4>
      </div>
      <RfExperimentStatistics
        v-if="!currExperiment.is_completed && isCurrExperimentRunning"
        :metrics="currExperimentMetrics"
        :pieActionGroupId.sync="pieActionGroupId"
        :model="path"
        :isSurvey="isSurvey"
        :hasData="customMetricsTotal"
        :surveyChartData="surveyChartData"
        @showVariantDetails="showVariantDetails"
        @fetchMetrics="fetchMetrics"
      />
      <div
        v-else-if="isSurvey && currExperiment.is_completed"
        class="mt-4 flex flex-col gap-4"
        style="padding: 0 15px"
      >
        <RfSelect
          v-model="pieActionGroupId"
          hide-details
          class="ml-auto mr-0"
          outlined
          dense
          :items="displayVariations"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
        />
        <div class="flex gap-8">
          <v-card style="flex: 2">
            <div class="segment-overview-header retention-stat-h bg-white-1">
              <h4>Survey Interaction</h4>
            </div>
            <RfSurveyChart :hasData="customMetricsTotal" :surveyChartData="surveyChartData" />
          </v-card>
          <v-card class="h-full" style="flex: 1">
            <div class="segment-overview-header retention-stat-h bg-white-1">
              <h4>Survey Metrics</h4>
            </div>
            <RfSurveyMetricsTable
              :surveyChartData="surveyChartData"
              :totalData="customMetricsTotal"
            />
          </v-card>
        </div>
      </div>
    </v-card>
    <div class="experiment-variant-header">
      <h4>Variations</h4>
      <div>
        <RfButton
          icon="file_copy"
          button-text="Clone"
          class="mr-2"
          color="accent"
          :disabled="isDisabledRoleMixin || !currExperiment.is_completed || !!path.experiment"
          @click.stop="cloneDialog = true"
        />
        <RfButton
          icon="add"
          button-text="Add variation"
          class="mr-2"
          color="accent"
          :disabled="isDisabledRoleMixin || isCurrExperimentRunning || currExperiment.is_completed"
          @click.stop="addVariantDialog = true"
        />
        <RfButton
          icon="clear"
          button-text="Delete"
          color="info"
          :disabled="isDisabledRoleMixin || isCurrExperimentRunning || currExperiment.is_completed"
          @click.stop="openDeleteExpDialog"
        />
      </div>
    </div>
    <div class="all-segment-wrapper">
      <div
        class="all-segment-item-wrapper experiment-variant-item"
        v-for="variant in displayVariations"
        :key="variant.id"
      >
        <v-card>
          <RfVariantCard
            :variant="variant"
            :path="path"
            v-on:showTrafficDetail="showTrafficDetail"
            v-on:showEditVariant="showEditVariant(variant)"
            v-on:showDeleteModal="showDeleteModal(variant)"
            v-on:showVariantDetails="showVariantDetails(variant)"
          />
        </v-card>
      </div>
    </div>
    <RfEditAbVariant
      :path="path"
      :editVariantDialog="addVariantDialog"
      v-on:closeEditVariantModal="closeAddVariantModal"
      :currExperiment="currExperiment"
      :isNew="true"
      title="Add variation"
    />
    <RfEditAbVariant
      :path="path"
      :variantDetail="variantDetail"
      :editVariantDialog="editVariantDialog"
      v-on:closeEditVariantModal="closeEditVariantModal"
      :currExperiment="currExperiment"
      :key="variantDetail.id"
      title="Edit variation"
    />
    <RfEditAbVariant
      :path="path"
      :editVariantDialog="showVariantDetailDialog"
      v-on:closeEditVariantModal="closeVariantDetails"
      :currExperiment="currExperiment"
      :variantDetail="variantDetail"
      :key="variantDetail.id + 'control'"
      :disabled="true"
      title="Variation details"
    />
    <v-dialog
      overlay-opacity="0.85"
      v-model="cloneDialog"
      width="500"
      content-class="show-variant-dialog"
      scrollable
    >
      <v-card flat>
        <v-card-title class="headline">Are you sure you want to clone?</v-card-title>
        <v-card-text>
          Cloning an experiment overwrites the configuration of the prompt. This cannot be undone.
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="cloneDialog = false"
            >Cancel</v-btn
          >
          <v-btn large depressed color="accent" @click="clone()" style="width: 150px">Clone</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      overlay-opacity="0.85"
      v-model="deleteExpDialog"
      width="500"
      content-class="show-variant-dialog"
      scrollable
    >
      <v-card flat>
        <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
        <v-card-text>
          Deleting an experiment removes the experiment and all variations. This cannot be undone.
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="closeDeleteExpDialog"
            >Cancel</v-btn
          >
          <v-btn large depressed color="error" @click="submitDeleteExperiment" style="width: 150px"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      overlay-opacity="0.85"
      v-model="showDeleteDialog"
      width="500"
      content-class="show-variant-dialog"
      scrollable
    >
      <v-card flat>
        <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
        <v-card-text>
          Deleting a variation removes all information about this variation. This cannot be undone.
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDeleteModal"
            >Cancel</v-btn
          >
          <v-btn
            large
            depressed
            color="error"
            @click="deleteVariant(variantDetail)"
            style="width: 150px"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="trafficEditDialog"
      width="600"
      content-class="show-traffic-dialog"
    >
      <v-card flat>
        <v-card-title class="headline">Traffic Distribution</v-card-title>
        <v-card-text style="padding-bottom: 5px">
          <div>Your experiment's traffic distribution must add up to 100%</div>
          <v-simple-table class="rf-settings-table-data variant-traffic-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="variant-traffic-col1">Variation Name</th>
                  <th class="variant-traffic-col2">Traffic %</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(variant, idx) in currExperiment.variations" :key="idx">
                  <td class="traffic-col-name">
                    {{ variant.name }}
                  </td>
                  <td class="traffic-col-percent">
                    <div class="variant-traffic-percent-col">
                      <v-select
                        v-model="trafficPercentageValues[variant.id]"
                        :disabled="trafficDisabled"
                        :items="trafficPercentItems"
                        placeholder="Select"
                        outlined
                        dense
                        background-color="#ffffff"
                        hide-details
                        @change="totalSumTraffic = calculateTotalSumTraffic()"
                      ></v-select>
                    </div>
                  </td>
                </tr>
                <tr class="variant-traffic-tbl-total">
                  <td class="variant-total-text">
                    <span v-if="totalSumTraffic !== 100">Total traffic must equal 100%</span>
                  </td>
                  <td>
                    <div
                      :class="
                        totalSumTraffic !== 100 ? 'trafic-total--error' : 'trafic-total--passed'
                      "
                    >
                      {{ totalSumTraffic }}%
                      <v-icon right size="18" color="error" v-if="totalSumTraffic !== 100"
                        >error</v-icon
                      >
                      <v-icon size="18" color="success" right v-else>fas fa-check</v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            text
            color="accent"
            style="margin-right: 150px"
            @click="resetTraffic"
            :disabled="this.currExperiment.is_active || this.currExperiment.is_completed"
            ><v-icon left>refresh</v-icon>Reset Traffic</v-btn
          >
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="130px"
            @click="hideTrafficDetail"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="totalSumTraffic !== 100"
            large
            depressed
            color="accent"
            width="130px"
            @click="saveTraffic"
          >
            <v-icon left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editNameDialog"
      overlay-opacity="0.85"
      width="500"
      content-class="show-variant-dialog"
    >
      <v-card flat>
        <v-card-title class="headline">Experiment Name</v-card-title>
        <v-card-text>
          <v-text-field
            dense
            outlined
            autofocus
            placeholder="Name"
            label="Name"
            v-model.trim="experimentName"
            hide-details
            style="margin: 10px 0"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="closeEditNameDialog"
            >Cancel</v-btn
          >
          <v-btn large depressed color="accent" @click="submitNameUpdate" style="width: 150px"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import RfEditAbVariant from "@/components/RfPathCreate/RfRetentionExperiment/RfEditAbVariant.vue";
import RfRetentionExperimentDetail from "@/components/RfPathCreate/RfRetentionExperiment/RfRetentionExperimentDetail.vue";
import RfRetentionExperimentResultTable from "@/components/RfPathCreate/RfRetentionExperiment/RfRetentionExperimentResultTable.vue";
import RfVariantCard from "@/components/RfPathCreate/RfRetentionExperiment/RfVariantCard.vue";
import RfExperimentStatistics from "@/components/RfCommonCards/RfExperimentStatistics.vue";
import MetricsMixin from "@/utils/MetricsMixin";
import RecentActivityMixin from "@/utils/RecentActivityMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { getExperimentLink } from "@/utils/getLink";
import RfSelect from "@/components/inputs/RfSelect.vue";
import { isSurvey } from "@/utils/prompts/promptHelpers";
import {
  PATH_CUSTOM_METRIC_PIECHART,
  PATH_CUSTOM_METRIC_SURVEY,
} from "@/utils/constants/CustomMetricsConstants";
import RfSurveyMetricsTable from "@/blocks/RfTable/RfSurveyMetricsTable.vue";
import RfSurveyChart from "@/blocks/RfCharts/RfSurveyChart.vue";
import StringUtils from "@/utils/StringUtils";
import { getSurveyInputs } from "@/utils/metricsHelpers";
import { isInvisible } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfRetentionExperimentView",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: {
    RfEditAbVariant,
    RfRetentionExperimentDetail,
    RfRetentionExperimentResultTable,
    RfVariantCard,
    RfButton,
    RfExperimentStatistics,
    RfSelect,
    RfSurveyMetricsTable,
    RfSurveyChart,
  },
  mixins: [MetricsMixin, RecentActivityMixin, RoleMixin],
  data() {
    return {
      pieActionGroupId: null,
      updating: false,
      addVariantDialog: false,
      editVariantDialog: false,
      showDeleteDialog: false,
      showVariantDetailDialog: false,
      trafficEditDialog: false,
      variantDetail: {},
      actions: {},
      cloneDialog: false,
      totalSumTraffic: 0,
      trafficPercentageValues: {},
      deleteExpDialog: false,
      editNameDialog: false,
      experimentName: null,
      sequenceId: null,
      pipelineId: null,
      currChartSrc: null,
    };
  },
  computed: {
    ...mapState({
      path: state => state.apps.currPath,
      currExperiment: state => state.apps.currExperiment,
      lastError: state => state.lastError,
      currExperimentMetrics: state => state.apps.currPathActionGroupMetrics,
      currExperimentCustomMetrics: state => state.apps.currExperimentCustomMetrics,
    }),
    ...mapGetters(["isCurrExperimentRunning"]),
    trafficPercentItems() {
      return [...Array(101).keys()]; // 0 - 100
    },
    trafficDisabled() {
      return !!(this.currExperiment.is_active || this.currExperiment.is_completed);
    },
    displayVariations() {
      return isInvisible(this.path)
        ? this.currExperiment.variations
        : this.currExperiment.variations.filter(item => item.is_visible && !item.is_control);
    },
    isSurvey() {
      return isSurvey(this.path);
    },
    customMetrics() {
      return (
        this.currExperimentCustomMetrics?.[
          this.isSurvey ? PATH_CUSTOM_METRIC_SURVEY : PATH_CUSTOM_METRIC_PIECHART
        ]?.[this.pieActionGroupId]?.[this.currChartSrc]?.agg_data?.[2] || {}
      );
    },
    customMetricsTotal() {
      return Object.values(this.customMetrics).reduce((a, e) => a + e, 0);
    },
    surveyInputs() {
      return getSurveyInputs(this.path);
    },
    surveyChartData() {
      return [...Array(this.surveyInputs.labels.length).keys()].map(index => {
        const label = this.surveyInputs.labels[index];
        const value = this.customMetrics[this.surveyInputs.values[index]] || 0;
        const percentage = this.customMetricsTotal
          ? StringUtils.formatStatFixed((value * 100) / this.customMetricsTotal)
          : 0;
        return [
          label,
          {
            label,
            value,
            percentage,
            v: value,
            f: `${label} ${percentage}`,
          },
          `${label} (<strong>${percentage}</strong>)`,
        ];
      });
    },
  },
  methods: {
    ...mapActions([
      "getPath",
      "getExperiment",
      "runExperiment",
      "updateExperiment",
      "cloneExperiment",
      "deleteExperiment",
      "updatePath",
      "getExperimentMetrics",
      "getExperimentCustomMetrics",
    ]),
    calculateTotalSumTraffic() {
      return Object.values(this.trafficPercentageValues).reduce((a, b) => a + b, 0);
    },
    async clone() {
      const clonedExperiment = await this.cloneExperiment({
        pathId: this.path.id,
        experimentId: this.currExperiment.id,
      });
      await this.getExperiment({ pathId: this.path.id, expId: clonedExperiment.id });
      this.$router.push({
        path: getExperimentLink(clonedExperiment.id, this.path.id, this.$route.params.aid),
      });
      this.cloneDialog = false;
    },
    getUserStats(variant) {
      if (variant.is_control && !variant.is_visible) return variant.stats.uholdouts || 0;
      return variant.stats.impressions ? variant.stats.impressions : 0;
    },
    variantTrafficDistribution(percent) {
      return percent || 0;
    },
    saveExperiment(exp = {}) {
      this.updateExperiment({
        pathId: this.path.id,
        expId: this.currExperiment.id,
        modelExperiment: { ...this.currExperiment, ...(exp || {}) },
      });
    },
    async onStart(pausePromo) {
      this.isUpdating = true;
      const appId = this.$route.params.aid;
      const pathId = this.path.id;
      await this.runExperiment({
        appId,
        pathId,
        expId: this.currExperiment.id,
        isStart: !this.isCurrExperimentRunning,
      });
      if (pausePromo) {
        const modelPath = cloneDeep(this.path);
        modelPath.is_enabled = false;
        this.updatePath({
          appId,
          pathId,
          modelPath,
        })
          .then(() => null)
          .catch(() => null);
      }
    },
    closeAddVariantModal() {
      this.addVariantDialog = false;
    },
    closeEditVariantModal() {
      this.editVariantDialog = false;
    },
    showEditVariant(variant) {
      this.editVariantDialog = true;
      this.variantDetail = variant;
    },
    showDeleteModal(variant) {
      this.showDeleteDialog = true;
      this.variantDetail = variant;
    },
    closeDeleteModal() {
      this.showDeleteDialog = false;
    },
    showVariantDetails(variant) {
      this.showVariantDetailDialog = true;
      this.variantDetail = variant;
    },
    closeVariantDetails() {
      this.showVariantDetailDialog = false;
    },
    deleteVariant(variant) {
      const modelExperiment = {
        ...this.currExperiment,
        variations: this.currExperiment.variations.filter(({ id }) => id !== variant.id),
      };

      this.updateExperiment({
        pathId: this.path.id,
        expId: this.currExperiment.id,
        modelExperiment,
      });
      this.toastsStore.create({ type: "success", body: `Variation ${variant.name} deleted` });
      this.showDeleteDialog = false;
    },
    showTrafficDetail() {
      this.trafficPercentageValues = {};
      this.currExperiment.variations.forEach(variation => {
        this.trafficPercentageValues[variation.id] = variation.traffic_percent;
      });
      this.trafficEditDialog = true;
    },
    hideTrafficDetail() {
      this.trafficEditDialog = false;
    },
    saveTraffic() {
      this.currExperiment.variations.forEach(
        variation => (variation.traffic_percent = this.trafficPercentageValues[variation.id]),
      );
      this.saveExperiment();
      this.hideTrafficDetail();
    },
    updateExperimentDate({ type, value }) {
      this.saveExperiment({ [type]: value });
    },
    resetTraffic() {
      this.trafficPercentageValues = {};
      this.currExperiment.variations.forEach(variation => (variation.traffic_percent = 0));
      this.saveExperiment();
    },
    openDeleteExpDialog() {
      this.deleteExpDialog = true;
    },
    closeDeleteExpDialog() {
      this.deleteExpDialog = false;
    },
    submitDeleteExperiment() {
      this.closeDeleteExpDialog();
      const experimentId = this.currExperiment.id;
      const pathId = this.path.id;
      this.deleteExperiment({ pathId, experimentId }).then(() => {
        this.$router.replace({
          path: `/apps/${this.$route.params.aid}/retentions/${pathId}`,
        });
      });
    },
    openEditNameDialog() {
      this.experimentName = this.currExperiment.name;
      this.editNameDialog = true;
    },
    closeEditNameDialog() {
      this.experimentName = this.currExperiment.name;
      this.editNameDialog = false;
    },
    submitNameUpdate() {
      this.currExperiment.name = this.experimentName;
      this.saveExperiment();
      this.closeEditNameDialog();
    },
    fetchMetrics(params) {
      this.getExperimentMetrics({
        pathId: this.$route.params.pid,
        appId: this.$route.params.aid,
        experimentId: this.$route.params.eid,
        params,
      });
      this.currChartSrc = params.metric_periods[0].period;
      this.isSurvey &&
        this.getExperimentCustomMetrics({
          pathId: this.$route.params.pid,
          id: this.$route.params.eid,
          params: { ...params, metric_keys: [PATH_CUSTOM_METRIC_SURVEY] },
        });
    },
  },
  beforeRouteEnter: (_, from, next) =>
    next(vm => {
      vm.sequenceId = from.params.sid || null;
      vm.pipelineId = from.params.plid || null;
    }),
  async mounted() {
    const appId = this.$route.params.aid;
    const pathId = this.$route.params.pid;
    await this.getPath({ appId, pathId });
    await this.getExperiment({ pathId, expId: this.$route.params.eid });
    this.updatePathActivity(appId, pathId);
    this.totalSumTraffic = this.calculateTotalSumTraffic();
    if (
      !(!this.currExperiment?.is_completed && this.isCurrExperimentRunning) &&
      this.isSurvey &&
      this.currExperiment?.is_completed
    ) {
      this.pieActionGroupId = this.displayVariations?.[0]?.id || null;
      this.fetchMetrics({ metric_periods: [{ period: "since_start_date" }] });
    }
  },
  watch: {
    $route(to) {
      this.getPath({
        appId: to.params.aid,
        pathId: to.params.pid,
      });
    },
    currExperiment() {
      if (this.isUpdating) {
        this.toastsStore.create({ type: "success", body: "Experiment updated!" });
        this.closeAddVariantModal;
        this.isUpdating = false;
        this.$forceUpdate();
      }
    },
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
