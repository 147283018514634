<template>
  <div>
    <div class="experiment-pg-module experiment-details-mod">
      <h4 style="padding-top: 10px">Details</h4>
      <div class="experiment-details-wrapper-col">
        <div class="promo-hero-col-wrapper" style="padding: 10px 0 10px 0">
          <div class="promo-hero-col left-col">
            <RfPromoDetailsInfoRow title="Status">
              <template v-slot:promodetail>
                <div
                  class="experiment-detail-value"
                  v-if="selectedExperiment.is_active && !selectedExperiment.is_completed"
                >
                  <v-icon color="success" left size="18">fiber_manual_record</v-icon>Active
                </div>
                <div
                  class="experiment-detail-value"
                  v-if="!selectedExperiment.is_active && !selectedExperiment.is_completed"
                >
                  not started
                </div>
                <div
                  class="experiment-detail-value"
                  v-if="!selectedExperiment.is_active && selectedExperiment.is_completed"
                >
                  <v-icon color="#666666" left size="18">fiber_manual_record</v-icon>Completed
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="Traffic Distribution">
              <template v-slot:promodetail>
                <div
                  class="experiment-detail-value traffic-distr-val"
                  :style="{ cursor: isReadOnlyRoleMixin ? 'not-allowed' : 'pointer' }"
                  @click="isReadOnlyRoleMixin ? null : $emit('showTrafficDetail')"
                >
                  {{ trafficDistribution }}%
                  <span class="error-exp-traffic" v-if="trafficError">
                    <v-icon size="16" color="error" left>error</v-icon>
                    <span> Must add up to 100% </span>
                  </span>
                  <v-icon size="16" color="success" right v-else>fas fa-check</v-icon>
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="Control">
              <template v-slot:promodetail>
                <div
                  class="experiment-detail-value"
                  :style="{ cursor: isReadOnlyRoleMixin ? 'not-allowed' : 'pointer' }"
                  @click="isReadOnlyRoleMixin ? null : $emit('showTrafficDetail')"
                >
                  {{ holdoutPercentage }}%
                </div>
              </template>
            </RfPromoDetailsInfoRow>
          </div>
          <div class="promo-hero-col right-col">
            <RfPromoDetailsInfoRow title="Dates">
              <template v-slot:promodetail>
                <div class="experiment-detail-value inline-flex items-center gap-2">
                  Created: {{ renderDate(selectedExperiment.created_at) }}
                  <span class="exp-last-updated-date">Last updated:</span>
                  {{ renderDate(selectedExperiment.updated_at) }}
                  <RfTooltip class="mr-0 w-fit">
                    <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
                    <template #tooltip>
                      <div>Time zone: {{ currApp.timezone_offset }}</div>
                    </template>
                  </RfTooltip>
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="Start date">
              <template v-slot:promodetail>
                <div>
                  <div class="experiment-detail-value inline-flex items-center gap-2">
                    <div>
                      {{
                        selectedExperiment.start_date === null
                          ? "N/A"
                          : renderDate(selectedExperiment.start_date)
                      }}
                    </div>
                    <RfTooltip v-if="selectedExperiment.start_date !== null" class="mr-0 w-fit">
                      <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
                      <template #tooltip>
                        <div>Time zone: {{ currApp.timezone_offset }}</div>
                      </template>
                    </RfTooltip>
                  </div>
                  <div class="pd-info-edit">
                    <RfButton
                      ref="startDateButton"
                      v-if="isExperimentDrafted"
                      icon-size="16"
                      title="edit"
                      icon="edit"
                      color="accent"
                      x-small
                      @click="
                        updateDate = {
                          type: 'start_date',
                          value: selectedExperiment.start_date,
                        }
                      "
                    />
                  </div>
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="End date">
              <template v-slot:promodetail>
                <div>
                  <div class="experiment-detail-value inline-flex items-center gap-2">
                    {{
                      selectedExperiment.end_date === null
                        ? "N/A"
                        : renderDate(selectedExperiment.end_date)
                    }}
                    <RfTooltip v-if="selectedExperiment.end_date !== null" class="mr-0 w-fit">
                      <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
                      <template #tooltip>
                        <div>Time zone: {{ currApp.timezone_offset }}</div>
                      </template>
                    </RfTooltip>
                  </div>
                  <div class="pd-info-edit">
                    <RfButton
                      ref="endDateButton"
                      v-if="isExperimentRunning || isExperimentDrafted"
                      icon-size="16"
                      title="edit"
                      icon="edit"
                      color="accent"
                      x-small
                      @click="updateDate = { type: 'end_date', value: selectedExperiment.end_date }"
                    />
                  </div>
                </div>
              </template>
            </RfPromoDetailsInfoRow>
          </div>
        </div>
      </div>
      <v-dialog v-model="experimentStartDialog" width="700">
        <v-card flat>
          <v-card-title class="headline">
            <span v-if="isCurrExperimentRunning">End experiment confirmation</span>
            <span v-else>Start experiment confirmation</span>
          </v-card-title>
          <v-card-text>
            <div v-if="isCurrExperimentRunning">Are you sure you want to stop this experiment?</div>
            <div v-else-if="!path.is_enabled">
              Your prompt will be published. Are you sure you want to start this experiment?
            </div>
            <div v-else>Are you sure you want to start this experiment?</div>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn
              large
              depressed
              class="cancel-btn"
              outlined
              width="100px"
              @click.stop="experimentStartDialog = false"
              >Close</v-btn
            >
            <div class="exp-running-buttons" v-if="isCurrExperimentRunning">
              <v-btn
                large
                depressed
                color="success"
                class="expriment-winner-button"
                @click="submitExperimentBtn(false)"
                >End experiment</v-btn
              >
              <v-btn
                large
                depressed
                color="accent"
                class="expriment-winner-button"
                @click="submitExperimentBtn(true)"
                >End experiment & pause prompt</v-btn
              >
            </div>
            <v-btn
              v-else
              large
              depressed
              color="success"
              width="150px"
              @click="submitExperimentBtn(false)"
              >Start</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="experiment-action-btns" v-if="!isExperimentCompleted">
      <RfButton
        :icon="!isCurrExperimentRunning ? 'fas fa-play-circle' : 'fas fa-stop-circle'"
        :button-text="!isCurrExperimentRunning ? 'Start experiment' : 'End experiment'"
        large
        color="success"
        :disabled="isDisabledRoleMixin"
        @click="callExperimentDialog(isCurrExperimentRunning)"
      />
      <span style="padding-left: 1rem" v-if="!isCurrExperimentRunning"
        >Once the experiment is started, add variation is no longer available.</span
      >
    </div>
    <v-menu
      v-if="updateDate.type"
      :activator="updateDate.type === 'start_date' ? startDateButton : endDateButton"
      :value="updateDate.type"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :key="updateDate.type"
      min-width="290px"
      @input="updateDate = { type: null, value: null }"
    >
      <div class="bg-white-1">
        <div class="flex-shrink flex-grow-0 flex-col items-start gap-4 overflow-y-auto">
          <v-date-picker
            no-title
            :value="updateDate.value && dayjsDate.format('YYYY-MM-DD')"
            :min="getMinDate()"
            :key="updateDate.value"
            :max="getMaxDate()"
            @input="updateDateValue('date', $event)"
          />
          <RfTimePicker
            class="overflow-hidden"
            :initTime="
              updateDate.value && StringUtils.intervalIndexByHhmm(dayjsDate.format('hh:mm'))
            "
            ref="timePicker"
            dense
            @updateTime="updateDateValue('time', $event)"
          />
        </div>
        <div class="flex w-full pb-2 pt-4">
          <button
            class="text-buttons ml-auto mr-4 p-2 disabled:!text-grey-3"
            :disabled="!updateDate.value"
            @click="$emit('updateDate', updateDate), (updateDate = { type: null, value: null })"
          >
            Apply
          </button>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import { mapGetters, mapState } from "vuex";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { useToastsStore } from "@/pinia/toastsStore";
import dayjs from "dayjs";
import { ref } from "vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfTimePicker from "@/components/RfTimePicker.vue";
import StringUtils from "@/utils/StringUtils";
import TimezonesConstants from "@/utils/constants/TimezonesConstants";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfHelpIcon from "@/components/icons/RfHelpIcon.vue";

export default {
  name: "RfRetentionExperimentDetail",
  props: ["selectedExperiment", "path"],
  components: { RfPromoDetailsInfoRow, RfButton, RfBaseModal, RfTimePicker, RfTooltip, RfHelpIcon },
  mixins: [RoleMixin],
  setup: () => ({
    toastsStore: useToastsStore(),
    dayjs,
    startDateButton: ref(),
    endDateButton: ref(),
    updateDateModal: ref(),
    StringUtils,
  }),
  data: () => ({
    experimentStartDialog: false,
    tempDate: null,
    updateDate: { type: null, value: null },
  }),
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    ...mapGetters(["isCurrExperimentRunning"]),
    isExperimentEnabled() {
      return this.selectedExperiment.is_enabled;
    },
    isExperimentRunning() {
      return this.isExperimentEnabled;
    },
    isExperimentDrafted() {
      return (
        !this.isExperimentEnabled &&
        (this.selectedExperiment.end_date === null ||
          dayjs(this.selectedExperiment.end_date).isAfter(dayjs()))
      );
    },
    isExperimentFinished() {
      return !this.isExperimentEnabled && dayjs(this.selectedExperiment.end_date).isBefore(dayjs());
    },
    isExperimentCompleted() {
      return this.selectedExperiment.is_completed;
    },
    trafficDistribution() {
      return this.selectedExperiment.variations.reduce(function (total, item) {
        return total + item.traffic_percent;
      }, 0);
    },
    holdoutPercentage() {
      const holdout = this.selectedExperiment.variations.find(item => item.is_control);
      if (holdout) return holdout.traffic_percent;
      return 0;
    },
    trafficError() {
      return this.trafficDistribution !== 100;
    },
    dayjsDate() {
      return dayjs(this.updateDate.value || undefined).tz(
        TimezonesConstants[this.currApp.timezone_offset],
      );
    },
  },
  methods: {
    callExperimentDialog(isExperimentRunning) {
      if (isExperimentRunning) {
        this.experimentStartDialog = true;
      } else if (
        this.selectedExperiment.variations.reduce(
          (total, item) => total + item.traffic_percent,
          0,
        ) !== 100
      ) {
        this.toastsStore.create({
          type: "error",
          body: "Your traffic percentages must add up to 100%",
        });
      } else {
        this.experimentStartDialog = true;
      }
    },
    submitExperimentBtn(pausePromo) {
      this.$emit("onStart", pausePromo);
      this.experimentStartDialog = false;
    },
    renderDate(date) {
      return dayjs(date)
        .tz(TimezonesConstants[this.currApp.timezone_offset])
        .format("MMM Do YYYY, hh:mm a");
    },
    showUpdateModal() {
      this.$nextTick(() => this.updateDateModal.show());
    },
    getMinDate() {
      if (this.updateDate.type === "end_date")
        return dayjs(Math.max(+dayjs(), +dayjs(this.selectedExperiment.start_date || undefined)))
          .add(1, "day")
          .toISOString();

      return dayjs().toISOString();
    },
    getMaxDate() {
      if (this.updateDate.type === "start_date" && this.selectedExperiment.end_date)
        return dayjs(this.selectedExperiment.end_date).subtract(1, "day").toISOString();

      return undefined;
    },
    updateDateValue(type, value) {
      let tempDate = this.dayjsDate.clone();

      if (type === "date") {
        const [yyyy, mm, dd] = value.split("-");
        tempDate = tempDate
          .set("year", yyyy)
          .set("month", mm - 1)
          .set("date", dd);
      }
      if (type === "time") {
        const [hh, mm] = StringUtils.hhmmBy30MinIntervalIndex(value).split(":");
        tempDate = tempDate.set("hour", hh).set("minute", mm);
      }

      this.updateDate = { ...this.updateDate, value: tempDate.toISOString() };
    },
  },
};
</script>
<style>
.exp-running-buttons .v-btn {
  margin-left: 8px;
}
.error-exp-traffic {
  padding-left: 10px;
  color: #c00;
  display: flex;
  align-items: center;
  line-height: normal;
}
.traffic-distr-val {
  display: flex;
  align-items: center;
}
.exp-last-updated-date {
  padding-left: 15px;
}
</style>
