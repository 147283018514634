<template>
  <div class="date-selector-wrapper">
    <v-dialog
      content-class="datepicker-modal-div"
      v-model="showModalDatePicker"
      persistent
      width="500px"
    >
      <v-card flat>
        <v-card-title class="headline">Select start and end dates:</v-card-title>
        <v-card-text>
          <div persistent width="500px">
            <v-date-picker
              full-width
              range
              v-model="dateRange"
              :min="minDate"
              :max="maxDate"
              scrollable
              header-color="#e4f3ff"
            >
              <v-spacer>{{ showDatepickerModal }}</v-spacer>
            </v-date-picker>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn depressed class="cancel-btn" outlined width="100px" @click="cancelDatePickerModal"
            >Cancel</v-btn
          >
          <v-btn depressed color="accent" @click="setSelectedDates()" style="width: 120px"
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="date-selector date-selector-end">
      <v-text-field
        :value="renderDate"
        placeholder="Select dates"
        readonly
        :outlined="outlined"
        dense
        hide-details
        @click.stop="showModalDatePicker = true"
        style="width: 220px"
        background-color="#fff"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "RfCustomMetricsDatePicker",
  props: ["min_date", "max_date", "value", "modalDatePicker", "outlined"],
  data() {
    return {
      minDate: this.min_date ? new Date(this.min_date).toISOString().substr(0, 10) : null,
      maxDate: this.max_date ? new Date(this.max_date).toISOString().substr(0, 10) : null,
      dateRange: [],
      startDate: null,
      endDate: null,
      currStartDate: null,
      showModalDatePicker: false,
    };
  },
  watch: {
    dateRange(to) {
      this.currStartDate = to;
      this.reorderDates(to);
    },
  },
  methods: {
    setSelectedDates() {
      this.showModalDatePicker = false;
      if (!this.renderDate) {
        this.$emit("closeCustomDateRange");
      }
      this.setDateRangeDisplay(this.currStartDate);
    },
    cancelDatePickerModal() {
      this.showModalDatePicker = false;
      if (!this.renderDate) {
        this.$emit("closeCustomDateRange");
      }
    },
    setDateRangeDisplay(dates) {
      this.$emit("input", this.reorderDates(dates));
    },
    reorderDates(dates) {
      return dates.slice().sort((a, b) => {
        if (new Date(a) > new Date(b)) {
          this.endDate = a;
          this.startDate = b;
        } else {
          this.startDate = a;
          this.endDate = b;
        }
        return new Date(a) - new Date(b);
      });
    },
  },
  computed: {
    showDatepickerModal() {
      if (this.modalDatePicker) {
        this.showModalDatePicker = true;
      } else {
        this.showModalDatePicker = false;
      }
    },
    renderDate() {
      return this.startDate
        ? `${dayjs(this.startDate).format("MMM Do, YYYY")} - ${dayjs(this.endDate).format(
            "MMM Do, YYYY",
          )}`
        : "";
    },
  },
  mounted() {
    if (this.value) {
      this.dateRange = this.value;
      this.setDateRangeDisplay(this.dateRange);
    }
  },
};
</script>

<style lang="scss" scoped>
.date-selector-wrapper {
  padding: 0;
}
</style>
