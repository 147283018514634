<template>
  <RfSimpleCard>
    <div class="px-7 pt-5">
      <div
        v-if="app.web_snippet_settings?.userid_store_type"
        class="mb-3 flex flex-row gap-3 rounded bg-goal px-5 py-3"
      >
        <RfWarningStatusIcon class="mt-0.5" />
        <div>
          Your
          <RouterLink :to="{ path: `/apps/${app.id}/settings/user_id` }"
            >User ID Matching settings</RouterLink
          >
          are currently overriding the <code>fetchUserId</code> function below.
          <br />
          To use the below, select "Fetch from: Custom JS Snippet" instead.
        </div>
      </div>
      <v-card flat class="rf-custom-web-card-wrapper">
        <PrismEditor v-model="code" language="js"></PrismEditor>
      </v-card>
    </div>
  </RfSimpleCard>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import PrismEditor from "vue-prism-editor";
import RfWarningStatusIcon from "../icons/RfWarningStatusIcon.vue";

export default {
  name: "RfCustomWebSnippet",
  components: {
    RfSimpleCard,
    RfWarningStatusIcon,
    PrismEditor,
  },
  props: ["app"],
  data() {
    return {
      success: false,
      code: this.getSnippet(),
    };
  },
  methods: {
    fillModel(model) {
      model.web_snippet = this.code;
      const success = model.web_snippet && !!model.web_snippet.length;
      if (!success) {
        throw new Error("Web snippet requires a value");
      }
    },
    getSnippet() {
      if (this.app && this.app.web_snippet && `${this.app.web_snippet}`.trim().length > 0) {
        return this.app.web_snippet;
      }
      return `export default class Settings {
  static fetchUserId() {
    // REQUIRED FIELD
    /*
    You can fetch data from your frontend to send to us.
    Data can come from cookies, localstorage, db, or anywhere else
    EXAMPLE BELOW
    */
    /*
    const user = localStorage.getItem("user_object");
    return user.id
    */

    return null;
  }

  static fetchAnonUserId() {
    /*
    You can fetch data from your frontend to send to us.
    Data can come from cookies, localstorage, db, or anywhere else

    Same as fetchUserId(), but for anonymous users
    */

   return null;
  }

  static fetchUserTraits() {
    /*
    You can fetch data from your frontend to send to us.
    Data can come from cookies, localstorage, db, or anywhere else
    EXAMPLE BELOW
    */
    /*
    const user = localStorage.getItem("user_object");
    return {
      is_registered: !!user.token,
      member_since: !!user.registrationDate,
      accepted_tos: localStorage.getItem("tos_acceptance_date")
    }
    */

    return null;
  }

  static canShowPromotion() {
    /*
    You can specify any app-wide conditions under which prompts should never be shown
    Should return a Boolean. If value is false, no overlay prompts will be shown
    EXAMPLE BELOW
    */

    /*
    return !!document.querySelector('.full-screen-video-player')
    */
    return true;
  }

  static canPing() {
    /* You can specify whether the user has opted into being identified on by Redfast.
    If the value is false user information will not be collected.
    Can be used if user has opted out of CCPA-GDPR.
    */

   return true;
  }

  static setLanguage() {
    /* You can specify the language of the user. Will control the display language of prompts
    that have languages configured. Should return a 2-letter or 4-letter language code.
    */
    return null
  }
}`;
    },
  },
};
</script>
