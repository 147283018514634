<template>
  <div v-if="currApp" class="rf-category-pg-wrapper">
    <div class="all-segments-content-div" :class="{ 'hide-subnav-menu': !subnavOpenStatus }">
      <RfSettingList />
      <div class="all-segments-list-rightcol">
        <v-toolbar flat>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <v-toolbar-title class="font-weight-bold ml-4">
                {{ SettingTitles[currSetting]?.title || SettingTitles[currSetting] || "Settings" }}
              </v-toolbar-title>
              <a
                v-if="SettingTitles[currSetting]?.docs"
                target="_blank"
                title="View docs"
                class="ml-2"
                :href="SettingTitles[currSetting].docs"
              >
                <v-icon>mdi-help-circle-outline</v-icon>
              </a>
            </div>
          </div>
          <div class="mr-4">
            <RfButtonNew
              v-if="SettingTitles[currSetting]?.action"
              :text="SettingTitles[currSetting].action"
              @click="$refs[currSetting].click()"
            />
          </div>
        </v-toolbar>
        <div class="rf-detail-content-wrapper">
          <div class="rf-settings-content">
            <RfSystem
              v-if="currSetting === 'system'"
              :model="currApp"
              :key="renderKey"
              ref="system"
            />
            <RfSetupUserId
              v-if="currSetting === 'user_id'"
              :app="currApp"
              :key="renderKey"
              ref="user_id"
            />
            <RfSetupUsageTracking
              v-if="currSetting === 'usage_tracking'"
              :app="currApp"
              :key="renderKey"
              ref="usage_tracking"
            />
            <RfSetupCustomTraits
              v-if="currSetting === 'segment_traits'"
              :app="currApp"
              :key="renderKey"
              ref="segment_traits"
            />
            <RfSettingsTags
              v-if="currSetting === 'tags'"
              :app="currApp"
              :key="renderKey"
              ref="tags"
            />
            <RfSimpleCard
              v-if="currSetting === 'triggers'"
              :class="triggerTab === 'custom_triggers' ? 'rf-retention-form' : 'pb-10'"
            >
              <div class="settings-actions-tabs mb-10">
                <div :class="{ selectedTab: triggerTab === 'triggers' }">
                  <button @click="triggerTab = 'triggers'">Triggers</button>
                </div>
                <div :class="{ selectedTab: triggerTab === 'custom_triggers' }">
                  <button @click="triggerTab = 'custom_triggers'">Advanced Triggers</button>
                </div>
              </div>
              <RfTriggerSettings
                v-if="triggerTab === 'triggers'"
                :currApp="currApp"
                :key="renderKey"
              />
              <RfClientActions
                v-if="triggerTab === 'custom_triggers'"
                :app="currApp"
                :key="renderKey"
                ref="custom_triggers"
                actionType="custom_trigger"
              />
            </RfSimpleCard>
            <RfDataSourceSettings v-if="currSetting === 'data_sources'" />
            <RfSimpleCard v-if="isConnector">
              <div class="settings-actions-tabs">
                <div :class="{ selectedTab: actionTab === 'connectors' }">
                  <button @click="actionTab = 'connectors'">External</button>
                </div>
                <div :class="{ selectedTab: actionTab === 'custom_connectors' }">
                  <button @click="actionTab = 'custom_connectors'">API Actions</button>
                </div>
                <div :class="{ selectedTab: actionTab === 'client_actions' }">
                  <button @click="actionTab = 'client_actions'">Website Actions</button>
                </div>
              </div>
              <RfConnectors
                v-if="actionTab === 'connectors'"
                :app="currApp"
                :key="renderKey"
                ref="connectors"
                v-on:onSettingsUpdateClick="onSettingsUpdateClick"
              />
              <RfCustomConnectors
                v-if="actionTab === 'custom_connectors'"
                :app="currApp"
                :key="renderKey"
                ref="custom_connectors"
              />
              <RfClientActions
                v-if="actionTab === 'client_actions'"
                :app="currApp"
                :key="renderKey"
                ref="client_actions"
                actionType="website_action"
              />
            </RfSimpleCard>
            <RfPlacementZones v-if="currSetting === 'zones'" />
            <RfCustomDevices v-if="currSetting === 'custom_devices'" />
            <RfPipelineSettings
              v-if="currSetting === 'pipelines' && isAdminRoleMixin"
              :app="currApp"
              :key="renderKey"
            />
            <RfManageUsers
              v-if="currSetting === 'users'"
              :currApp="currApp"
              :key="renderKey"
              @tabChange="v => (userTab = v)"
            />
            <RfAppMessages v-if="currSetting === 'app_messages'" :messages="currApp.messages" />
            <RfCustomWebSnippet
              v-if="currSetting === 'custom_web_snippet' && isAdminRoleMixin"
              :app="currApp"
              :key="renderKey"
              ref="custom_web_snippet"
            />
            <RfCssStyles
              v-if="currSetting === 'css_styles' && isSuperAdminRoleMixin"
              ref="css_styles"
            />
            <RfDownloadSettings
              v-if="currSetting === 'download_csv'"
              :app="currApp"
              :key="renderKey"
            />
            <RfAppAdmin
              v-if="currSetting === 'admin' && isSuperAdminRoleMixin"
              :model="currApp"
              :key="renderKey"
              ref="admin"
            />
            <RfSalesAdmin
              v-if="currSetting === 'sales_admin' && (isSalesRoleMixin || isSuperAdminRoleMixin)"
              :model="currApp"
              :key="renderKey"
              ref="sales_admin"
            />
            <RfAwsMarketplaceSettings
              v-if="currSetting === 'aws_marketplace'"
              ref="awsMarketplaceSettings"
            />

            <v-card v-if="showSaveButton" class="d-flex rf-retention-form-btn-div">
              <RfButton
                button-text="Save changes"
                icon="save"
                class="ml-auto mr-11 px-10"
                data-cy="settings--save-button"
                depressed
                x-large
                color="primary"
                :disabled="isDisabledRoleMixin"
                @click="onSettingsUpdateClick"
              />
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import { mapActions, mapState } from "vuex";
import RfSettingList from "@/components/RfSettings/RfSettingList.vue";
import RfSystem from "@/components/RfSettings/RfSystem.vue";
import RfSetupCustomTraits from "@/components/RfSettings/RfSetupCustomTraits.vue";
import RfSetupUserId from "@/components/RfSettings/RfSetupUserId.vue";
import RfSetupUsageTracking from "@/components/RfSettings/RfSetupUsageTracking.vue";
import RfManageUsers from "@/components/RfSettings/RfManageUsers.vue";
import RfAppAdmin from "@/components/RfSettings/RfAdmin.vue";
import RfSalesAdmin from "@/components/RfSettings/RfSalesAdmin.vue";
import RfConnectors from "@/components/RfSettings/RfConnectors.vue";
import RfCustomConnectors from "@/components/RfSettings/RfCustomConnectors.vue";
import RfCustomWebSnippet from "@/components/RfSettings/RfCustomWebSnippet.vue";
import RfCssStyles from "@/components/RfSettings/RfCssStyles.vue";
import RfClientActions from "@/components/RfSettings/RfClientActions.vue";
import RfPlacementZones from "@/components/RfSettings/RfPlacementZones.vue";
import RfTriggerSettings from "@/components/RfSettings/RfTriggerSettings.vue";
import RfPipelineSettings from "@/components/RfSettings/RfPipelineSettings.vue";
import RfDownloadSettings from "@/components/RfSettings/RfDownloadSettings.vue";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import * as Sentry from "@sentry/browser";
import SettingTitles from "@/utils/constants/SettingTitlesConstants";
import RfDataSourceSettings from "@/components/RfSettings/RfDataSourceSettings.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfCustomDevices from "@/components/RfSettings/RfCustomDevices.vue";
import RfAwsMarketplaceSettings from "@/views/RfAwsMarketplaceSettings.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import RfAppMessages from "@/components/RfSettings/RfAppMessages.vue";
import RfSettingsTags from "@/components/RfSettings/RfSettingsTags/RfSettingsTags.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";

export default {
  name: "RfSettings",
  setup: () => ({ toastsStore: useToastsStore(), SettingTitles }),
  components: {
    RfSettingList,
    RfSystem,
    RfManageUsers,
    RfSetupCustomTraits,
    RfAppAdmin,
    RfSalesAdmin,
    RfConnectors,
    RfSetupUserId,
    RfSetupUsageTracking,
    RfCustomConnectors,
    RfCustomWebSnippet,
    RfCssStyles,
    RfClientActions,
    RfPlacementZones,
    RfTriggerSettings,
    RfPipelineSettings,
    RfDownloadSettings,
    RfSimpleCard,
    RfButton,
    RfCustomDevices,
    RfDataSourceSettings,
    RfAwsMarketplaceSettings,
    RfAppMessages,
    RfSettingsTags,
    RfButtonNew,
  },
  mixins: [RoleMixin],
  data() {
    return {
      deletingApp: false,
      updating: false,
      renderKey: 0,
      actionsTabs: null,
      actionTab: "connectors",
      userTab: "tab5",
      triggerTab: "triggers",
    };
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => cloneDeep(state.apps.currApp),
      paths: state => cloneDeep(state.apps.paths),
      lastError: state => state.lastError,
      orgs: state => state.apps.orgs,
      subnavOpenStatus: state => state.apps.subnavOpenStatus,
    }),
    currSetting() {
      return this.$route.params.pathMatch || "system";
    },
    isConnector() {
      return this.currSetting.match(/connectors\b/);
    },
    showSaveButton() {
      const hideButtonSettings = [
        "usage_tracking",
        "segment_traits",
        "tags",
        "triggers",
        "data_sources",
        "zones",
        "custom_devices",
        "pipelines",
        "app_messages",
        "download_csv",
      ];
      if (hideButtonSettings.includes(this.currSetting)) return false;
      if (this.isConnector && this.actionTab !== "custom_connectors") return false;
      if (this.currSetting === "users" && ["tab2", "tab3", "tab4", "tab5"].includes(this.userTab))
        return false;

      return true;
    },
  },
  methods: {
    async onSettingsUpdateClick() {
      try {
        if (this.currSetting === "aws_marketplace")
          return this.$refs.awsMarketplaceSettings.updateSettings();

        const newApp = cloneDeep(this.currApp);
        let fillModel;
        if (this.isConnector) {
          fillModel = this.$refs[this.actionTab].fillModel;
        } else if (this.currSetting === "triggers" && this.triggerTab === "custom_triggers") {
          fillModel = this.$refs[this.triggerTab].fillModel;
        } else if (this.$refs[this.currSetting]) {
          fillModel = this.$refs[this.currSetting].fillModel;
        }
        if (fillModel) {
          fillModel(newApp);
        }

        this.updating = true;
        const includeProviders =
          this.isConnector ||
          this.currSetting === "custom_connectors" ||
          this.currSetting === "user_id";
        await this.updateApp({ appObj: newApp, includeProviders });
        this.renderKey++;
      } catch (error) {
        this.toastsStore.create({ type: "error", body: `${error}. Please try again.` });
        Sentry.captureException(error);
      }
    },
    ...mapActions(["updateApp"]),
  },
  watch: {
    apps(to) {
      if (this.deletingApp) {
        if (to.length > 0) {
          this.$router.push({ path: `/apps/${to[0].id}/segments` });
        } else {
          this.$router.push({ path: "/" });
        }
      }
    },
    currApp(to) {
      if (to == null) {
        this.$router.back();
      } else if (this.updating) {
        this.toastsStore.create({ type: "success", body: "Settings updated successfully" });
        this.updating = false;
      }
    },
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-detail-content-wrapper {
  margin-top: 10px;
  max-width: 1600px;
  padding-left: 32px;
  padding-right: 32px;
}

::v-deep .rf-settings-content {
  .v-card.rf-retention-form {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .v-card.rf-retention-form-btn-div {
    padding-bottom: 40px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}
</style>
