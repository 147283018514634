<template>
  <div class="retention-editor-page retention-editor-body" v-if="path">
    <div class="retention-editor-detail">
      <div class="retention-editor-name">
        <div>
          <RfBreadcrumbs
            breadcrumb-key="retention-creative"
            :path-name="path.name"
            :sequence="{ id: path.sequence_id, name: path.sequence_name }"
            :pipeline="{ id: path.pipeline_stage_id, name: path.pipeline_name }"
          />
          <h3>{{ path.name }}</h3>
        </div>
        <div class="creative-save-btn">
          <v-card-actions>
            <v-btn
              v-if="isSuperAdminRoleMixin"
              depressed
              color="secondary"
              outlined
              width="150"
              @click="applyGlobalStyles"
            >
              Apply Styles
            </v-btn>
            <v-btn
              v-if="currApp.flags.device_api_implementation"
              depressed
              color="primary"
              outlined
              width="150"
              @click="$refs.devicePayload.show(currLanguage)"
            >
              View payload
              <RfDeviceApiPayloadModal ref="devicePayload" />
            </v-btn>
            <RfTestEmailSender :path="path" v-if="isEmail" />
            <v-btn style="width: 150px" color="#efefef" depressed @click="livePreviewShow = true">
              <!-- v-if="isStandaloneWeb" -->
              <v-icon left>open_in_new</v-icon>Live Preview
            </v-btn>
            <RfButton
              icon="save"
              button-text="Save"
              color="primary"
              :disabled="isDisabledRoleMixin"
              :tooltip="{ bottom: true }"
              style="width: 150px"
              @click="submitConfigurationAndStay"
            />
            <RfButton
              icon="exit_to_app"
              button-text="Save & Exit"
              color="accent"
              :disabled="isDisabledRoleMixin"
              :tooltip="{ bottom: true }"
              style="width: 150px"
              @click="submitConfigurationAndExit"
            />
          </v-card-actions>
        </div>
      </div>
    </div>
    <RfRetentionCreativeEditor :model="path" ref="editor" v-on:setTabType="setTabType" />
    <RfDomainChooserWrapper
      :app="currApp"
      :visible="livePreviewShow"
      v-on:open="openLivePreview"
      v-on:close="closeLivePreview"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { cloneDeep } from "lodash-es";
import RfRetentionCreativeEditor from "@/components/RfPathCreate/RfRetentionCreativeEditor.vue";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import LiveMixin from "@/utils/LiveMixin";
import RecentActivityMixin from "@/utils/RecentActivityMixin";
import RfTestEmailSender from "@/components/RfPromotions/RfTestEmailSender.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import html2canvas from "html2canvas";
import RoleMixin from "@/utils/RoleMixin";
import RfBreadcrumbs from "../components/RfBreadcrumbs.vue";
import RfDeviceApiPayloadModal from "@/components/RfDeviceApiPayloadModal.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { isEmail, isStandaloneWeb } from "@/utils/prompts/promptHelpers";
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";

export default {
  name: "RfRetentionCreative",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: {
    RfRetentionCreativeEditor,
    RfDomainChooserWrapper,
    RfTestEmailSender,
    RfButton,
    RfBreadcrumbs,
    RfDeviceApiPayloadModal,
  },
  mixins: [LiveMixin, RecentActivityMixin, RoleMixin],
  data: () => ({ livePreviewShow: false, viewTypeTab: null, path: null }),
  computed: {
    ...mapState({
      currPath: state => state.apps.currPath,
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      lastError: state => state.lastError,
      experiments: state => state.apps.experiments,
      currLanguage: state => state.apps.currLanguage,
    }),
    isStandaloneWeb() {
      return isStandaloneWeb(this.path);
    },
    isEmail() {
      return isEmail(this.path);
    },
  },
  methods: {
    setTabType(type) {
      this.viewTypeTab = type;
    },
    async submitConfigurationAndExit() {
      await this.submitConfiguration(true);
    },
    async applyGlobalStyles() {
      const path = cloneDeep(this.path);
      const styles = this.currApp.styles;

      const mappings = {
        rf_settings_bg_image_color: "body.background-color",
        rf_settings_fill_color: "body.color",
        button1_bg_color: "button.background-color",
        button1_text_color: "button.color",
        button2_text_color: "button2.color",
        button3_text_color: "button2.color",
        rf_settings_title_font_size: "title.font-size",
        rf_settings_message_font_size: "message.font-size",
        rf_settings_mobile_title_font_size: "title2",
      };

      for (const [key, stylePath] of Object.entries(mappings)) {
        const styleKeys = stylePath.split(".");
        const value = styleKeys.reduce((acc, k) => acc && acc[k], styles);
        if (path.actions[key] && value) {
          path.actions[key] = value;
        }
      }
      const metadata = this.fontMetadata(
        styles["@font-face"]["src-published"] || styles["@font-face"]["src"],
      );
      const internalFont = metadata.find(font => font.type === "internal");
      const externalFont = metadata.find(font => font.type === "external");

      if (internalFont) {
        path.actions.rf_settings_custom_css += `
@font-face {
  font-family: "${styles["@font-face"]["font-family"]}";
  font-style: "${styles["@font-face"]["font-style"]}";
  font-weight: "${styles["@font-face"]["font-weight"]}";
  src: "${styles["@font-face"]["src-published"].replace(/"/g, "'")}";
}
        `;
      } else if (externalFont) {
        path.actions.rf_settings_custom_css += `\n@import url('${externalFont.url}');\n`;
      }
      const overlay = ["retention_modal", "video", "widget", "interstitial", "bottom_banner"];
      const banner = ["horizontal", "tile", "vertical"];
      if (overlay.includes(path.path_type) && styles["@font-face"]["font-family"]) {
        if (path.path_type === "interstitial" && ["ios", "android_os"].includes(path.device_type)) {
          path.actions.rf_settings_custom_css += `.mweb-widget-container {
  font-family: ${styles["@font-face"]["font-family"]}
}\n`;
        }
        if (path.device_type !== "ios") {
          path.actions.rf_settings_custom_css += `
.rfmodal-header h1, .rfmodal-message, .rfmodal-footer button, .rfmodal-countdown,
.rfmodal-header-mobileweb h3, .rfmodal-body-mobileweb .rfmodal-message-mobileweb,
.rfmodal-footer-mobileweb, .rfmodal-footer-mobileweb button, .modal-privacy-tos {
  font-family: ${styles["@font-face"]["font-family"]};
}\n`;
        }
      } else if (banner.includes(path.path_type)) {
        path.actions.rf_settings_custom_css += `.promo-tile-wrapper-container,
.rtile-mweb-content{
  font-family: ${styles["@font-face"]["font-family"]}\n;
}`;
      } else if (path.path_type == "text") {
        path.actions.rf_settings_custom_css += `.promo-text-wrapper, .promo-text-wrapper-mobile {
  font-family: ${styles["@font-face"]["font-family"]}\n;
};`;
      } else if (path.path_type === "email") {
        path.actions.rf_settings_custom_css += `.pr-email-wrapper, .pr-email-footer {
  font-family: ${styles["@font-face"]["font-family"]}\n;
}`;
      }
      this.path = path;
    },
    fontMetadata(cssText) {
      const fontURLs = [];
      const fontExtensions = [".woff", ".woff2", ".ttf", ".otf", ".eot", ".svg"];

      const urlRegex = /url\(["']?(.*?)["']?\)/g;
      let match;

      while ((match = urlRegex.exec(cssText)) !== null) {
        fontURLs.push(match[1]);
      }

      function checkFontURL(url) {
        if (url.includes("fonts.googleapis.com") || url.includes("fonts.gstatic.com")) {
          return "external";
        }

        for (const extension of fontExtensions) {
          if (url.endsWith(extension)) {
            return "internal";
          }
        }
      }

      const results = fontURLs.map(url => ({
        url,
        type: checkFontURL(url),
      }));

      return results;
    },
    async submitConfigurationAndStay() {
      await this.submitConfiguration(false);
    },
    async emailScreenshot(path) {
      const desktopButton = document.querySelector(".desktop-email-tab");
      if (desktopButton) {
        await desktopButton.click();
      }
      const preview = document.querySelector(".pr-email-preview");
      await html2canvas(preview, { allowTaint: true, useCORS: true }).then(canvas => {
        const image = canvas.toDataURL("image/png");
        path.actions.rf_settings_email_preview_composite = image;
      });
    },
    async submitConfiguration(exitOnSave = false) {
      try {
        const newPath = cloneDeep(this.path);
        if (this.$refs.editor) {
          await this.$refs.editor.fillModel(newPath);
        }
        if (this.isEmail) await this.emailScreenshot(newPath);

        const isNewAndroidEditor =
          this.currApp?.flags?.new_android_editor &&
          newPath.device_type === DefaultDeviceOptions.android_os.value;
        const isNewAppleEditor =
          !this.currApp?.flags?.legacy_apple_editor &&
          [DefaultDeviceOptions.ios.value, DefaultDeviceOptions.tv_os.value].includes(
            newPath.device_type,
          );

        if (isNewAndroidEditor || isNewAppleEditor) {
          if (!newPath.actions.rf_retention_button2_text)
            newPath.actions.rf_settings_confirm_button_2_enabled = false;
          if (!newPath.actions.rf_retention_button3_text)
            newPath.actions.rf_settings_cancel_button_enabled = false;
        }
        await this.updatePath({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
          modelPath: newPath,
        });

        if (!this.currPath || exitOnSave) return this.$router.back();
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
        this.path = cloneDeep(this.currPath);
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
      return null;
    },
    ...mapActions(["getPath", "updatePath", "getExperiments"]),
    openLivePreview(domain) {
      this.viewPreview(domain, { actionGroupId: this.path.action_group_id });
      this.closeLivePreview();
    },
    closeLivePreview() {
      this.livePreviewShow = false;
    },
  },
  async mounted() {
    const appId = this.$route.params.aid;
    const pathId = this.$route.params.pid;
    await Promise.all([this.getPath({ appId, pathId }), this.getExperiments({ pathId })]);
    this.updatePathActivity(appId, pathId);
    this.path = this.currPath;
  },
  watch: {
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
