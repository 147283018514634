export default class StringUtils {
  static formatStatFixed(stat) {
    const numberStat = +stat;
    return Number.isNaN(numberStat) || stat === null ? 0 : `${numberStat.toFixed(2)}%`;
  }
  static upperCaseEachWord(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  static metricsNumberFormat(value, short = true) {
    if (value >= 1000000000) {
      const shortValue = value / 1000000000;
      return `${shortValue.toFixed(2)} ${short ? "B" : `Billion${shortValue === 1 ? "" : "s"}`}`;
    }
    if (value >= 1000000) {
      const shortValue = value / 1000000;

      return `${shortValue.toFixed(2)} ${short ? "M" : `Million${shortValue === 1 ? "" : "s"}`}`;
    }
    if (value >= 1000) {
      const shortValue = value / 1000;

      return `${shortValue.toFixed(2)} ${short ? "K" : `Thousand${shortValue === 1 ? "" : "s"}`}`;
    }
    return value;
  }

  static pluralize = (word, amount) => (+amount === 1 ? word : `${word}s`);

  static truncate = (text, stop, clamp) =>
    text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");

  static capitalize = value =>
    value ? value.toString().charAt(0).toUpperCase() + value.toString().slice(1).toLowerCase() : "";

  static hhmmBy30MinIntervalIndex(index) {
    if (index === 48) return "23:59";
    const hrs = Math.floor((index * 30) / 60);
    const mins = index * 30 - hrs * 60;
    return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
  }

  static hhmmBy30MinIntervalIndex2(index) {
    if (index === 48) return 23 * 100 + 59;
    const hrs = Math.floor((index * 30) / 60);
    const mins = index * 30 - hrs * 60;
    return hrs * 100 + mins;
  }

  static hhmm2IntervalIndex(hhmm) {
    const index = (Math.floor(hhmm / 100) * 60 + (hhmm % 100)) / 30;
    return StringUtils.hhmmBy30MinIntervalIndex(index);
  }

  static intervalIndexByHhmm(hhmm) {
    if (hhmm === "23:59") return 48;
    const [hh, mm] = hhmm.split(":");
    return Number(hh) * 2 + Math.floor(Number(mm) / 30);
  }
}
