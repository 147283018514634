<template>
  <div class="rf-left-nav-bar !pt-18" data-cy="main-navigation">
    <div class="!mx-auto flex-grow-0 !px-2 pb-4" :class="{ '2xl:!mx-0 2xl:!px-5': wideSidebar }">
      <RfMenu
        class="rf-menu w-full"
        :preset="wideSidebar ? null : 'appSelectorSmall'"
        :hideIcon="!wideSidebar"
        :sameWidth="wideSidebar"
        :class="{ 'rf-menu--background': wideSidebar }"
      >
        <template #activator>
          <span
            data-cy="rf-app-selector--activator"
            :class="{ '2xl:text-body': wideSidebar }"
            class="text-heading-0 w-full !text-white-1"
          >
            <span :class="{ '2xl:flex': wideSidebar }" class="hidden">
              {{ currApp?.name || "App" }}
            </span>
            <span
              :class="{ '2xl:hidden': wideSidebar }"
              class="inline-grid aspect-square w-10 flex-shrink-0 place-items-center rounded !font-normal"
              :style="`background-color: ${getAppColor(currApp.id)}`"
            >
              {{ currApp.name[0] || "A" }}
            </span>
          </span>
        </template>
        <template #menu="{ close }">
          <div
            data-cy="rf-app-selector--list"
            class="list flex-shrink-1 flex min-w-36 flex-grow-0 flex-col gap-2 p-4"
          >
            <template v-for="app in apps">
              <button
                :key="app?.id"
                class="flex items-center gap-5 rounded p-1"
                @click="$emit('switchApp', app), close()"
              >
                <span
                  class="text-heading-0 inline-grid aspect-square w-10 flex-shrink-0 place-items-center rounded !font-normal !text-white-1"
                  :style="`background-color: ${getAppColor(app.id)}`"
                >
                  {{ app.name[0] }}
                </span>
                <span class="text-left">
                  {{ app.name }}
                </span>
              </button>
            </template>
            <RouterLink
              v-if="isSuperAdminRoleMixin"
              class="flex items-center gap-5 rounded p-1"
              to="/newapp"
              @click.native="close"
            >
              <v-icon size="40" color="primary">add_box</v-icon>Add New App
            </RouterLink>
          </div>
        </template>
      </RfMenu>
    </div>
    <div class="overflow-auto">
      <div class="leftnav-item speed-performance-mn" :style="deactivateLink('dashboard')">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :class="{ 'before:absolute 2xl:before:!w-1.5': wideSidebar }"
              :to="{ path: `/apps/${currApp.id}/dashboard` }"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="28">speed</v-icon>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                >
                  Performance
                </span>
              </div>
            </router-link>
          </template>
          <span>Performance</span>
        </v-tooltip>
      </div>
      <div class="leftnav-item" :style="deactivateLink('segments', true)">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :class="{ 'before:absolute 2xl:before:!w-1.5': wideSidebar }"
              :to="{ path: `/apps/${currApp.id}/segments` }"
              data-cy="main-navigation--segments"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="20">fas fa-users</v-icon>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Segments</span
                >
              </div>
            </router-link>
          </template>
          <span>Segments</span>
        </v-tooltip>
      </div>
      <div class="leftnav-item" :style="deactivateLink('prompts', true)">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              data-cy="main-navigation--prompts"
              :class="{
                'before:absolute 2xl:before:!w-1.5': wideSidebar,
                [activeClass('prompts')]: true,
              }"
              :to="{ path: `/apps/${currApp.id}/prompts` }"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="20" v-bind="attrs" v-on="on"
                    >fas fa-bullhorn</v-icon
                  >
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Prompts</span
                >
              </div>
            </router-link>
          </template>
          <span>Prompts</span>
        </v-tooltip>
      </div>
      <div
        class="leftnav-item"
        v-if="currApp.feature_set.indexOf('sequences') > -1"
        :style="deactivateLink('sequences', true)"
      >
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :class="{
                'before:absolute 2xl:before:!w-1.5': wideSidebar,
                [activeClass('sequences')]: true,
              }"
              :exact="true"
              class="leftnav-experiences-link"
              data-cy="main-navigation--guides"
              :to="{ path: `/apps/${currApp.id}/experiences` }"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="20">fas fa-project-diagram</v-icon>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Guides</span
                >
              </div>
            </router-link>
          </template>
          <span>Guides</span>
        </v-tooltip>
      </div>
      <div
        class="leftnav-item"
        v-if="currApp.feature_set.indexOf('pipelines') > -1"
        :style="deactivateLink('pipelines', true)"
      >
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :class="{
                'before:absolute 2xl:before:!w-1.5': wideSidebar,
                [activeClass('pipelines')]: true,
              }"
              :exact="true"
              :to="{ path: `/apps/${currApp.id}/pipelines` }"
              data-cy="main-navigation--pipelines"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <i><span class="leftnav-item-icon rf-pipeline-icon bg-icon"></span></i>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Pipelines</span
                >
              </div>
            </router-link>
          </template>
          <span>Pipelines</span>
        </v-tooltip>
      </div>
      <div class="leftnav-item" :style="deactivateLink('live', true)">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :class="{
                'before:absolute 2xl:before:!w-1.5': wideSidebar,
                [activeClass('live')]: true,
              }"
              :exact="true"
              :to="{ path: `/apps/${currApp.id}/live` }"
              data-cy="main-navigation--live"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="30">bolt</v-icon>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Live</span
                >
              </div>
            </router-link>
          </template>
          <span>Live</span>
        </v-tooltip>
      </div>

      <div class="leftnav-item" v-if="currApp.flags.ai_agent_guide_id">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :class="{
                'before:absolute 2xl:before:!w-1.5': wideSidebar,
                [activeClass('agent')]: true,
              }"
              :exact="true"
              class="leftnav-agent-link"
              data-cy="main-navigation--guides"
              :to="{ path: `/apps/${currApp.id}/agent/${currApp.flags.ai_agent_guide_id}` }"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <RfAgentIcon class="!h-7 !w-7" />
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Agent</span
                >
              </div>
            </router-link>
          </template>
          <span>Agent</span>
        </v-tooltip>
      </div>
      <div class="leftnav-item">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <button class="realtime-link-leftmenu w-full" @click="$emit('clickLive')">
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <RfPreviewIcon class="!h-7 !w-7" />
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Preview</span
                >
              </div>
            </button>
          </template>
          <span>Preview</span>
        </v-tooltip>
      </div>
      <div class="leftnav-item" :style="deactivateLink('settings')">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :to="{ path: `/apps/${currApp.id}/settings` }"
              :class="{ 'before:absolute 2xl:before:!w-1.5': wideSidebar }"
              data-cy="main-navigation--settings"
            >
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="20">fas fa-cog</v-icon>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                  >Settings</span
                >
              </div>
            </router-link>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </div>
      <div class="leftnav-item">
        <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
          <template v-slot:activator="{ on, attrs }">
            <a target="_blank" :href="docsUrl">
              <div
                class="!flex h-full w-full items-center !justify-center gap-3"
                :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
                v-bind="attrs"
                v-on="on"
              >
                <span class="inline-flex h-8 w-8 items-center justify-center">
                  <v-icon color="#AFB4BD" size="20">fas fa-book-open</v-icon>
                </span>
                <span
                  class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                  :class="{ '2xl:block': wideSidebar }"
                >
                  Docs
                </span>
              </div>
            </a>
          </template>
          <span>Docs</span>
        </v-tooltip>
      </div>
    </div>
    <div class="leftnav-item mb-6 mt-auto">
      <v-tooltip right :disabled="wideSidebar" nudge-right="-5">
        <template v-slot:activator="{ on, attrs }">
          <button class="realtime-link-leftmenu w-full" @click="logoutUser()">
            <div
              class="!flex h-full w-full items-center !justify-center gap-3"
              :class="{ '2xl:!justify-start 2xl:pl-7': wideSidebar }"
              v-bind="attrs"
              v-on="on"
            >
              <span class="inline-flex h-8 w-8">
                <RfExitIcon class="m-auto !h-6 !w-6" />
              </span>
              <span
                class="text-action-buttons hidden !font-medium !text-[#9FA8AF]"
                :class="{ '2xl:block': wideSidebar }"
                >Log out</span
              >
            </div>
          </button>
        </template>
        <span>Log out</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import RfExitIcon from "@/components/icons/RfExitIcon.vue";
import RfAgentIcon from "@/components/icons/RfAgentIcon.vue";
import RfPreviewIcon from "@/components/icons/RfPreviewIcon.vue";
import RfMenu from "@/components/menus/RfMenu.vue";
import RoleMixin from "@/utils/RoleMixin";
import { biggerOrEqual2xl } from "@/utils/composables/useBreakpoint";
import { getAppColor } from "@/utils/getAppColors";
import { computed, watch, ref } from "vue";
import { useRoute } from "vue-router/composables";
import { mapActions } from "vuex";

export default {
  components: { RfExitIcon, RfMenu, RfPreviewIcon, RfAgentIcon },
  name: "LeftMainNav",
  props: ["apps", "currApp", "docsUrl"],
  mixins: [RoleMixin],
  setup: props => {
    const root = document.querySelector(":root");
    const route = useRoute();
    const currentRouteName = computed(() => route.name);
    const allowedRoutes = computed(() =>
      [
        "App Dashboard",
        "All Segments",
        "All Prompts",
        "All Guides",
        "Pipelines",
        "Settings",
        "Company Settings",
        "Global Settings",
      ].includes(currentRouteName.value),
    );
    const wideSidebar = computed(() => allowedRoutes.value && biggerOrEqual2xl.value);

    watch(
      wideSidebar,
      v => {
        root.style.setProperty("--left-main-bar-width", v ? "240px" : "60px");
      },
      { immediate: true },
    );
    return { currentRouteName, getAppColor, wideSidebar };
  },
  data() {
    return {
      routesByParent: {
        dashboard: ["App Dashboard"],
        segments: ["All Segments", "Segment Detail"],
        prompts: [
          "All Prompts",
          "Placement Detail",
          "Retention Detail",
          "Retention root",
          "Retention Analysis",
          "Retention Details",
          "Retention Stats",
          "Retention Experiments",
          "Retention History",
        ],
        pipelines: ["Pipelines", "Pipeline Detail"],
        sequences: ["All Guides", "Guide Detail"],
        settings: ["Settings"],
        agent: ["Agent Detail"],
      },
    };
  },
  methods: {
    ...mapActions(["logoutUser"]),
    deactivateLink(page, exact = false) {
      const routes = this.routesByParent[page];
      if (exact && routes && routes.indexOf(this.currentRouteName) !== 0) return "";
      if (routes && routes.indexOf(this.currentRouteName) >= 0) return "pointer-events:none;";
      return "";
    },
    activeClass(page) {
      const routes = this.routesByParent[page];
      if (routes && routes.indexOf(this.currentRouteName) >= 0) return "router-link-active";
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.rf-menu.rf-menu--background.rf-menu .rf-menu--button {
  flex-grow: 1;
  padding: 8px 8px 8px 16px;
  border-radius: 4px;
  background: #146bb2;
}
</style>
